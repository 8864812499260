import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useRef, useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Button, Col } from 'reactstrap';
import Swal from 'sweetalert2';
import { useAlert } from 'react-alert';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import {
  addTimeToTimeSlots,
  constructFilemakerBaseUrl,
  determineCredentials,
  urlWithHttpProtocol,
  formatBearerToken,
  formatScheduleBlocksFromTimeslots,
  getCorrectEvalToken,
  getMeetingIdFromUrl,
  isArrayEqual,
  isDev,
  usePrevious,
  renderMeetingDetailsCard,
  informUserEvalLocked,
  getArrayIndexesWithDifferencesViaTimeSlot,
  getClosestFutureDate,
} from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import LoadingDot from '../../../../LoadingDot/LoadingDot';
import { useHistory } from 'react-router-dom';
import { clone } from '../../../../Services/schedule';
import {
  fetchDataAgnostic,
  fetchDataWithoutCredentials,
  putData,
  postDataAgnosticWithoutCredentials,
} from '../../../../Services/dataApi';
import Loading from 'Body/Statuses/Loading';

const candidateSortTypes = {
  Alphabetical: 'Alphabetical',
  EvalStatus: 'EvalStatus',
  University: 'University',
};

const urlV2 = `${constructFilemakerBaseUrl()}/evaluator/portal/`;
const scheduleBaseUrl = `${constructFilemakerBaseUrl()}/evaluator/candidates/`;
// const url =
// 'https://tools.rezrate.com/rezrate/api/evalportal/?token=kv78czgx78yb6gr0un1rg896y34645126262063170788677958307489916498670013146389725866310kthb05bde';

// handles mobile vs browser components.  Will reevaluate on resize

const WIDTH_BREAK_POINT = 767;

const getWidth = () => {
  return window.innerWidth < WIDTH_BREAK_POINT;
};

const filterCandidateInfo = (candidates) => {
  return candidates.filter((item) => {
    return item.Candidates4DateEvaluationStatus === 'In progress' || item.Candidates4DateEvaluationStatus === '';
  });
};

let updateInterval = null;
let inactivityInterval = null;

let oldGetInfoPayload = {};
const MobileFork = (props) => {
  const {
    // adjacentDates,
    BrowserComponent,
    handleDateChange,
    hasNoUsers,
    headerHeight,
    isAdmin,
    isEmbedded,
    match = { params: {} },
    MobileComponent,
    reduxDate,
    reduxDateArray,
    selectedEvaluator,
    setReduxDate,
    setReduxDateArray,
    setLargePhoto,
    setSeasonType,
    setShowLargePhoto,
    setUser,
    setZoomBreakRoom,
    interviewDates,
  } = props;

  const [adjacentDates, setAdjacentDates] = useState([]);
  const [beginningTimeMinus30Minutes, setBeginningTimeMinus30Minutes] = useState(null);
  const [candidateInfo, setCandidateInfo] = useState(null);
  const [copied, setCopied] = useState(false);
  const [currentCandidateSortType, setCurrentCandidateSortType] = useState(candidateSortTypes.Alphabetical);
  const [endingTimePlus30Minutes, setEndingTimePlus30Minutes] = useState(null);
  const [heightOfHeader, setHeightOfHeader] = useState(201);
  const [incompleteCandidates, setIncompleteCandidates] = useState([]);
  const [isMobile, setIsMobile] = useState(window.mobilecheck() || getWidth());
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [sortCandAscending, setSortCandAscending] = useState(false);
  const [userInformation, setUserInformation] = useState(null);

  const [allDayRoom, setAllDayRoom] = useState(null);
  const [mainRoomUrlDetails, setMainRoomUrlDetails] = useState('');
  const [candidateEvaluationStatus, setCandidateEvaluationStatus] = useState('');
  const [differences, setDifferences] = useState([]);
  const [disableJoinMeetingButton, setDisableJoinMeetingButton] = useState(false);
  const [DNRStatus, setDNRStatus] = useState(null);
  const [evaluationsLocked, setEvaluationsLocked] = useState(false);
  const [firstBlockEarlyJoin, setFirstBlockEarlyJoin] = useState(0);
  const [firstBlockEarlyJoinInMinutes, setFirstBlockEarlyJoinInMinutes] = useState(0);
  const [interviewIsToday, setInterviewIsToday] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isQuietLoading, setIsQuietLoading] = useState(false);

  const [mainRoomDescription, setMainRoomDescription] = useState(null);
  const [meetingId, setMeetingId] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [Schedules4EvalJoinManualURL, setSchedules4EvalJoinManualURL] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [userIdOnCurrentInterview, setUserIdOnCurrentInterview] = useState(null);
  const [virtualMeetingMode, setVirtualMeetingMode] = useState('');
  const [zoomPassword, setZoomPassword] = useState('');

  const [activeTab, setActiveTab] = useState('1');
  const [alarms, setAlarms] = useState(null);
  const [candidateEvaluation, setCandidateEvaluation] = useState(null);
  const [currentlyGettingEval, setCurrentlGettingEval] = useState(false);
  const [dataTypes, setDataTypes] = useState([]);
  const [demo, setDemo] = useState(false);
  const [error, setError] = useState(false);
  const [evalStatus, setEvalStatus] = useState(null);
  const [expiredToken, setExpiredToken] = useState(false);
  const [highlightIsFading, setHighlightIsFading] = useState(false); // Used to countdown for highlight.
  const [indexesWithDifferences, setIndexesWithDifferences] = useState([]);
  const [interviewHasPassed, setInterviewHasPassed] = useState(true);
  const [lockedStatuses, setLockedStatuses] = useState(null);
  const [previousDate, setPreviousDate] = useState(null);
  const [Schedules4Eval__pkUUID_Schedule, setSchedules4Eval__pkUUID_Schedule] = useState(null);
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [showRed, setShowRed] = useState(false);
  const [showMeetingCard, setShowMeetingCard] = useState(false);
  const [minutes, setMinutes] = useState(5 * 60 * 1000);
  const [zoomInfo, setZoomInfo] = useState([]);
  const [nonZoomInfo, setNonZoomInfo] = useState('');

  const [meetingLink, setMeetingLink] = useState(null);
  const [isFetchingReduxDateArray, setIsFetchingReduxDateArray] = useState(false);
  const [minutesToUpdateToDisplay, setMinutesToUpdateToDisplay] = useState(minutes || 5000);

  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isGettingLoggedInUser, setIsGettingLoggedInUser] = useState(false);
  let hasFiredInactivityPopup = false;
  const minutesToUpdateToDisplayRef = useRef(minutesToUpdateToDisplay || 5000);
  const activeTabRef = useRef(); // Used for getting updated activeTab inside setter.
  const reduxDateRef = useRef(); // Used for getting updated reduxDate inside setter.
  const highlightIsFadingRef = useRef();
  const loadingRef = useRef({ isQuietLoading: false, isLoading: false }); // used for getting updated loading state inside setter.We mustn't getInfo when we're still getting info.
  const isPrescreenRef = useRef(reduxDate && (reduxDate.pk_InterviewDate === 'Prescreen' || reduxDate === 'Prescreen')); // used for getting updated loading state inside setInterval getInfo updaters.We mustn't getInfo when we're still getting info.
  const pk_UserRef = useRef(); // To stop previously clicked user from overwriting last clicked user data.
  const pk_TimeSlotRef = useRef(); // To stop previously clicked candidate from overwriting last clicked candidate data.
  const prevAmount = usePrevious({ reduxDate });
  const adjacentDatesRef = useRef();

  const scheduleStartTime = useRef();
  const scheduleEndTime = useRef();

  const alert = useAlert();
  const history = useHistory();

  const { pk_User } = selectedEvaluator || {};
  const dContext = useContext(DepartmentContext);
  const { pk_InterviewDate } = match.params;

  const { getTokenSilently, user, logout, loginWithRedirect } = useAuth0();
  let previousDateIsDifferent = true;
  const isPrescreen = reduxDate && (reduxDate.pk_InterviewDate === 'Prescreen' || reduxDate === 'Prescreen');
  const inactivityWait = 15; // minutes

  useEffect(() => {
    if (error) {
      // Swal.fire(
      //   'Error',
      //   'Something went wrong when retrieving schedule data, please try again later or contact an administrator',
      //   'error',
      // );
    }
  }, [error]);

  useEffect(() => {
    loadingRef.current = { isQuietLoading, isLoading };
  }, [isQuietLoading, isLoading]);

  useEffect(() => {
    isPrescreenRef.current = isPrescreen;
  }, [isPrescreen]);

  // this component is used in two different places. One of the places relies on path paremeters to change the date
  // and the other relies on a changing redux state of a interview date object. To make these work in tandam without
  // having to fork logic too much, use the path params to set the selected date object
  useEffect(() => {
    // console.log('pk_InterviewDate changed! ', pk_InterviewDate);
    // console.log('reduxDateArray: ', reduxDateArray);
    // console.log('interviewDates: ', interviewDates);
    // console.log('interviewDates: ', interviewDates);
    // console.log('reduxDate: ', reduxDate);
    if (
      pk_InterviewDate &&
      reduxDateArray &&
      interviewDates &&
      // interviewDates.length > 0 &&
      (!reduxDate || pk_InterviewDate != reduxDate.pk_InterviewDate)
    ) {
      // need reduxDate to be defined for
      let selectedDate = reduxDate ? reduxDate : {};
      const pk_InterviewDateAsNumberType = Number(pk_InterviewDate);
      // if user is using prescreen just set the redux date to
      if (
        pk_InterviewDate.toUpperCase() === 'PRESCREEN' &&
        (!reduxDate || reduxDate.pk_InterviewDate !== 'Prescreen')
      ) {
        // console.log('prescreen detected. setting prescreen.');
        setReduxDate({ pk_InterviewDate: 'Prescreen' });
      } else {
        let dateFromPathParams = reduxDateArray.find((item) => {
          // console.log(
          //   'pk_InterviewDateAsNumberType: ',
          //   pk_InterviewDateAsNumberType,
          //   ' vs item.pk_InterviewDate: ',
          //   item.pk_InterviewDate,
          // );
          return item.pk_InterviewDate === pk_InterviewDateAsNumberType;
        });
        // console.log('dateFromPathParams: ', dateFromPathParams);
        // if a match is found and that match is not the already selected date, set it to that date
        if (dateFromPathParams && pk_InterviewDateAsNumberType !== selectedDate.pk_InterviewDate) {
          // console.log('date not selected, setting date from path params: ', dateFromPathParams);
          setReduxDate({ ...dateFromPathParams, allDayRoom: allDayRoom });
        } else if (!dateFromPathParams && reduxDateArray && reduxDateArray.length > 0) {
          // console.log('no date from params, using reduxDateArray 0');
          setReduxDate(reduxDateArray[0]);
        }
      }
    }
  }, [pk_InterviewDate, reduxDateArray, reduxDate, interviewDates]);

  useEffect(() => {
    reduxDateRef.current = reduxDate;
  }, [reduxDate]);

  useEffect(() => {
    setSelectedCandidate(null);
    if (reduxDate && dContext && dContext.department) {
      if (reduxDate.pk_InterviewDate !== previousDate) {
        previousDateIsDifferent = true;
      } else {
        previousDateIsDifferent = false;
      }
      setPreviousDate(reduxDate.pk_InterviewDate);
      const rightNow = new Date(Date.now());
      // const day = moment().format('MM/DD/YYYY'); OLD
      const day = moment.tz(dContext.department.TimeZone).format('MM/DD/YYYY');

      const interviewDate = moment.tz(
        `${reduxDate.DateOfInterview} ${reduxDate.StartTime}`,
        'YYYY-MM-DD',
        dContext.department.TimeZone,
      );

      if (day === interviewDate.format('MM/DD/YYYY')) {
        setInterviewIsToday(true);
      } else {
        setInterviewIsToday(false);
      }
      const isInPast = interviewDate.diff(moment(new Date(day)));
      if (isInPast < 0) {
        setInterviewHasPassed(true);
      } else {
        setInterviewHasPassed(false);
      }

      getAdjacentSchedule(reduxDateArray || [], reduxDate);
    }
    const isAdminAndEvaluatorSelected = isAdmin && pk_User;

    if (reduxDate && (isAdminAndEvaluatorSelected || !isAdmin)) {
      // console.log('useEffect getInfo1');
      getInfo(false);
      getNeedsAttention();
    } else if (reduxDate) {
      // console.log('useEffect getInfo2');
      getInfo(false);
    }
  }, [JSON.stringify(reduxDate), isAdmin, pk_User, reduxDate, reduxDateArray]);

  useEffect(() => {
    // console.log('allDayRoom setReduxDate: ', clone(reduxDate));
    setReduxDate({ ...reduxDate, allDayRoom: allDayRoom });
  }, [allDayRoom]);

  useEffect(() => {
    activeTabRef.current = activeTab;
    if (activeTab === '1' && indexesWithDifferences && !highlightIsFading) {
      setHighlightIsFading(true);
      setTimeout(() => {
        setIndexesWithDifferences([]);
        setHighlightIsFading(false);
      }, 10000);
    }
  }, [activeTab]);

  useEffect(() => {
    highlightIsFadingRef.current = highlightIsFading;
  }, [highlightIsFading]);

  useEffect(() => {
    let minutesToWait = 2 * 60 * 1000;
    if (isPrescreen) {
      minutesToWait = 10 * 60 * 1000;
    }
    setMinutes(minutesToWait);
    // setMinutes(65000);
    setMinutesToUpdateToDisplay(minutesToWait);
    minutesToUpdateToDisplayRef.current = minutesToWait;
    if (updateInterval) {
      clearInterval(updateInterval);
    }
    updateInterval = setInterval(() => {
      const { isQuietLoading, isLoading } = loadingRef.current;
      if (!isLoading && !isQuietLoading) {
        getInfo(true);
      } else {
        // console.log('still fetching, cancel update');
      }
    }, minutesToWait);

    return () => clearInterval(updateInterval);
  }, [currentCandidateSortType, sortCandAscending, selectedEvaluator, reduxDate]);

  // //if the user changes reset the candidante info
  useEffect(() => {
    // console.log('pk_User change!: ', pk_User);
    setCandidateInfo(null);
    setSelectedCandidate(null);

    if (pk_User != null || !isAdmin) {
      getNeedsAttention(reduxDate);
    }
    pk_UserRef.current = pk_User;
  }, [pk_User, isAdmin]);

  useEffect(() => {
    if (selectedCandidateId) {
      updateSelectedTimeSlotFromSelectedCandidate(selectedCandidateId);
    }
  }, [selectedCandidateId]);

  useEffect(() => {
    // if admin is selected but here's no pk_User don't fetch - Admin needs a user to select
    if (isAdmin && !pk_User) return;

    if (dContext && dContext.department && dContext.department.pk_Department) {
      getTokenSilently()
        .then((token) => {
          fetchDataAgnostic(
            'department/user',
            { pk_Department: dContext.department.pk_Department, pk_User, isAdmin },
            formatBearerToken(token),
          )
            .then((result) => {
              setUser(result.data);
              setUserInformation(result.data);
            })
            .catch((err) => {});
        })
        .catch((err) => {
          if (err.message === 'Login required') {
            loginWithRedirect();
          }
        });
    }
  }, [dContext, pk_User]);

  useEffect(() => {
    // Updates reduxDateArray when selected user or date is changed
    const validatepk_UserIfAdmin = (isAdmin && pk_User) || !isAdmin;
    // console.log('popping! ');
    // console.log('dContext: ', clone(dContext));
    // console.log('reduxDate: ', clone(reduxDate));
    // console.log('pk_User: ', pk_User);
    // console.log('isAdmin: ', isAdmin);
    // console.log('validatepk_UserIfAdmin:  ', validatepk_UserIfAdmin);
    // console.log('selectedEvaluator: ', selectedEvaluator);
    if (
      dContext &&
      dContext.department &&
      dContext.department.pk_Department &&
      (!reduxDate || reduxDate.pk_InterviewDate == null || reduxDateArray.length <= 0) &&
      validatepk_UserIfAdmin &&
      !isFetchingReduxDateArray
    ) {
      setIsFetchingReduxDateArray(true);
      // setTimeout(() => {
      getTokenSilently()
        .then((token) => {
          fetchDataAgnostic(
            'department/season/user/scheduledates',
            {
              pk_Department: dContext.department.pk_Department,
              pk_Season: dContext.season.pk_Season,
              isAdmin,
              pk_User,
            },
            formatBearerToken(token),
          )
            .then((result) => {
              const datesSortedByEarliest = result.data.sort((a, b) => {
                const firstMillis = moment(a.DateTimeObject).valueOf();
                const secondMillis = moment(b.DateTimeObject).valueOf();

                if (firstMillis < secondMillis) {
                  return -1;
                }
                if (firstMillis > secondMillis) {
                  return 1;
                }
                // a must be equal to b
                return 0;
              });

              if (JSON.stringify(reduxDateArray) !== JSON.stringify(datesSortedByEarliest)) {
                // console.log('setReduxDateArray: ', datesSortedByEarliest);
                setReduxDateArray(datesSortedByEarliest);
              }

              // Maybe this isn't needed here? Seems redundant with the other useEffect up top.
              return;
            })
            .catch((err) => {})
            .finally(() => {
              setIsFetchingReduxDateArray(false);
            });
        })
        .catch((err) => {
          if (err.message === 'Login required') {
            loginWithRedirect();
          }
        });
      // }, 10000);
    }
  }, [dContext, pk_User, reduxDate]);

  useEffect(() => {
    //TODO: Find a better way to wait for notifications to mount rather than just setting a timeout
    setTimeout(() => {
      const heightOfHeader = document.getElementById('header_notification_wrapper');
      if (heightOfHeader) {
        setHeightOfHeader(heightOfHeader.offsetHeight);
      }
    }, 1000);
    getDataTypes();

    setInterval(() => {
      if (minutesToUpdateToDisplayRef.current > 0) {
        setMinutesToUpdateToDisplay(minutesToUpdateToDisplayRef.current - 1000);
        minutesToUpdateToDisplayRef.current = minutesToUpdateToDisplayRef.current - 1000;
      }
    }, 1000);

    document.addEventListener('mousemove', () => {
      const now = moment();
      localStorage.setItem('lastActivity', now.toISOString());
    });
    document.addEventListener('click', () => {
      const now = moment();
      localStorage.setItem('lastActivity', now.toISOString());
    });

    checkInactivity(true);

    inactivityInterval = setInterval(() => {
      checkInactivity();
    }, 10000);

    return () => {
      clearAll();
    };
  }, []);

  useEffect(() => {
    if (!loggedInUser && !isGettingLoggedInUser && dContext) {
      getCurrentlyLoggedInUser();
    }
  }, [dContext]);

  useEffect(() => {
    if (schedule) {
      getScheduleStartAndEndTime(schedule);
    }
  }, [schedule]);

  useEffect(() => {
    // if (scheduleStartTime && scheduleEndTime) {
    //   console.log('scheduleStartTime: ', scheduleStartTime.current.format('MMM DD hh:mm A'));
    //   console.log('scheduleEndTime: ', scheduleEndTime.current.format('MMM DD hh:mm A'));
    // }
  }, [scheduleStartTime, scheduleEndTime]);

  const getScheduleStartAndEndTime = (scheduleToProcess) => {
    let newStartTime = null;
    let newEndTime = null;

    if (scheduleToProcess && scheduleToProcess.length > 0) {
      let index = 0;

      while (newStartTime === null && index < scheduleToProcess.length) {
        const scheduleRow = scheduleToProcess[index];

        if (!scheduleRow.isRedirect && scheduleRow.StartTime) {
          newStartTime = scheduleRow.StartTimeAsMoment;
        }
        index++;
      }

      index = scheduleToProcess.length - 1;

      while (newEndTime === null && index >= 0) {
        const scheduleRow = scheduleToProcess[index];

        if (!scheduleRow.isRedirect && scheduleRow.EndTime) {
          newEndTime = scheduleRow.EndTimeAsMoment;
        }
        index--;
      }

      scheduleStartTime.current = newStartTime;
      scheduleEndTime.current = newEndTime;
    }
  };

  const getCurrentlyLoggedInUser = () => {
    setIsGettingLoggedInUser(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic('department/user/permissions', null, formatBearerToken(token))
          .then((res) => {
            setIsGettingLoggedInUser(false);
            setLoggedInUser(res.data);
          })
          .catch((err) => {
            setIsGettingLoggedInUser(false);
            console.log('getUserDetails err: ', err);
          });
      })
      .catch((err) => {
        setIsGettingLoggedInUser(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const clearAll = () => {
    // console.log('clearing Mobile Fork!');
    oldGetInfoPayload = {};
    setReduxDate({});
    reduxDateRef.current = null;
    adjacentDatesRef.current = [];
    // setReduxDateArray([]);
    setUserInformation(null);
    clearInterval(inactivityInterval);
    setUser(null); // This is a redux thing. Reset user when unloading. Otherwise it will show the previous user when MobileFork us used on a different page
  };

  const momentizeUnavailableTimes = (result) => {
    if (result.data && result.data && result.data.body) {
      result.data.body.forEach((item) => {
        item.UnavailableOverlaps.forEach((unavailable) => {
          const startTimeFormatted = moment(unavailable.StartTime).format('H:mma');
          const endTimeFormatted = moment(unavailable.EndTime).format('H:mma');
          unavailable.StartTimeFormatted = startTimeFormatted;
          unavailable.EndTimeFormatted = endTimeFormatted;
        });
      });
    }
  };

  //TODO: depreacte this. Logic has been moved to Evaluation.js
  const checkIfEvalsAreLocked = () => {
    return false;
    if (lockedStatuses) {
      const { EnablePrescreenQuestionnaire, EnableEvaluationQuestionnaire } = lockedStatuses;

      const isPrescreen = reduxDate === 'Prescreen';

      if (isPrescreen && EnablePrescreenQuestionnaire !== true) {
        return true;
      }
      if (!isPrescreen && EnableEvaluationQuestionnaire !== true) {
        return true;
      }
    }
    return false;
  };

  // const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
  //   timeout: 30 * 60 * 1000,
  //   onIdle: handleOnIdle,
  //   onActive: handleOnActive,
  //   onAction: handleOnAction,
  //   debounce: 500,
  // });

  const getAdjacentSchedule = (dates, currentDate) => {
    const newAdjacentDates =
      dates.filter((d) => {
        // console.log(`d ${d.DateOfInterview} vs current ${currentDate.DateOfInterview}`);
        return d.DateOfInterview === currentDate.DateOfInterview;
        // && d.pk_InterviewDate != currentDate.pk_InterviewDate;
      }) || [];

    setAdjacentDates(newAdjacentDates);
    adjacentDatesRef.current = newAdjacentDates;
  };

  const getPrescreen = (quietly) => {
    // console.log('getPrescreen firing');
    if (!dContext || !dContext.department) {
      // console.log('no dContext or dContext.department');
      setMinutesToUpdateToDisplay(minutes);
      minutesToUpdateToDisplayRef.current = minutes;
      setIsLoading(false);
      setIsQuietLoading(false);
      return;
    }
    // console.log('selectedEvaluator: ', selectedEvaluator);
    const isAdminAndHasSelectedEvaluator = isAdmin && selectedEvaluator && Object.keys(selectedEvaluator).length !== 0;

    // console.log('isAdminAndHasSelectedEvaluator: ', isAdminAndHasSelectedEvaluator);

    // if it's the admin only run this step if an evaluator has been selected.
    // otherwise it will just use the evaluator that's signed in
    if (isAdminAndHasSelectedEvaluator || !isAdmin) {
      const payload = {
        pk_Department: dContext.department.pk_Department,
        pk_Season: dContext.season.pk_Season,
        mode: 'Prescreen',
      };

      let payloadHasDifference = false;

      Object.keys(payload).forEach((key) => {
        if (payload[key] != oldGetInfoPayload[key]) {
          payloadHasDifference = true;
        }
      });

      if (
        (selectedEvaluator && selectedEvaluator.pk_User != oldGetInfoPayload.pk_User) ||
        (!selectedEvaluator && oldGetInfoPayload.pk_User != null)
      ) {
        payloadHasDifference = true;
      }
      if (!payloadHasDifference && !quietly) {
        // console.log("getPrescreen cancelled, payload hasn't changed");
        setMinutesToUpdateToDisplay(minutes);
        minutesToUpdateToDisplayRef.current = minutes;
        setIsLoading(false);
        setIsQuietLoading(false);
        return;
      }
      if (!quietly) {
        setIsLoading(true);
      } else {
        setIsQuietLoading(true);
      }

      setError(false);

      oldGetInfoPayload = { payload, pk_User: selectedEvaluator ? selectedEvaluator.pk_User : null };
      getTokenSilently()
        .then((token) => {
          if (selectedEvaluator && selectedEvaluator.pk_User != null) {
            payload.pk_User = selectedEvaluator.pk_User;
            payload.isAdmin = 1;
          }
          fetchDataAgnostic('department/season/user/scheduledate/candidates', payload, formatBearerToken(token))
            .then((res) => {
              // console.log('getPrescreen data: ', res.data);
              // sortCandidatesAlphabetically(res.data);
              sortCandidates(currentCandidateSortType, false, res.data);
              setIsLoading(false);
              setIsQuietLoading(false);

              setMinutesToUpdateToDisplay(minutes);
              minutesToUpdateToDisplayRef.current = minutes;
            })
            .catch((err) => {
              setIsLoading(false);
              setIsQuietLoading(false);
            });
        })
        .catch((err) => {
          if (err.message === 'Login required') {
            loginWithRedirect();
          }
        });
    } else {
      setIsLoading(false);
      setIsQuietLoading(false);
    }
  };

  const handleZoomClick = (options) => {
    const { url, mobileNumbers, password, meetingId, nonZoomInfo } = options;

    // if there are mobile numbers set them.  If not,
    // make sure zoom info resets so it doesn't display
    // info from previous meeting
    if (password && password !== '') {
      setZoomPassword(password);
    } else {
      setZoomPassword('');
    }

    if (meetingId && meetingId !== '') {
      setMeetingId(meetingId);
    } else {
      setMeetingId('');
    }

    if (mobileNumbers && mobileNumbers.length > 0) {
      setZoomInfo(mobileNumbers);
    } else {
      setZoomInfo([]);
    }

    if (nonZoomInfo && nonZoomInfo !== '') {
      setNonZoomInfo(nonZoomInfo);
    } else {
      setNonZoomInfo('');
    }
    const zoomLinkWrapped = urlWithHttpProtocol(url);
    localStorage.setItem('zoomUrl', zoomLinkWrapped);
    setMeetingLink(zoomLinkWrapped);
    setShowMeetingCard(true);
    setTimeout(() => {
      window.open(url, '_blank');
    }, 1000);

    setTimeout(() => {
      setShowMeetingCard(false);
    }, 3 * 60 * 1000);
  };

  const informUserMeetingIsStartingLocally = (setDisplayLocalNotification) => {
    setDisplayLocalNotification(false);
    setTimeout(() => {
      setDisplayLocalNotification(true);
    }, 3 * 60 * 1000);
    NotificationManager.info('Click here to join!', 'Time for next meeting', 10 * 1000, () => {
      if (
        typeof Schedules4EvalJoinManualURL !== 'undefined' &&
        Schedules4EvalJoinManualURL !== '' &&
        Schedules4EvalJoinManualURL !== null
      ) {
        handleZoomClick({ url: Schedules4EvalJoinManualURL });
      } else {
        requestZoomMeeting(Schedules4Eval__pkUUID_Schedule);
      }
    });
  };

  const sortCandidatesAlphabetically = (incomingCandidates, isIncompleteCandidates, direction = 'asc') => {
    const sortAscending = direction === 'asc';
    let newCandidates = clone(incomingCandidates || candidateInfo || []);

    if (isIncompleteCandidates) {
      newCandidates = clone(incomingCandidates || incompleteCandidates);
    }

    newCandidates.sort((a, b) => {
      const textA = `${a.LastName}, ${a.FirstName}`;
      const textB = `${b.LastName}, ${b.FirstName}`;
      if (sortAscending) {
        return textA < textB ? 1 : textA > textB ? -1 : 0;
      } else {
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }
    });

    setCurrentCandidateSortType(candidateSortTypes.Alphabetical);

    if (isIncompleteCandidates) {
      setIncompleteCandidates(newCandidates);
      return;
    }

    setCandidateInfo(newCandidates);
    return;
  };

  const sortByUniversity = (incomingCandidates, isIncompleteCandidates, direction = 'asc') => {
    const sortAscending = direction === 'asc';

    if (isIncompleteCandidates) {
      setIncompleteCandidates((prevState) => {
        const candidates = clone(incomingCandidates || prevState);
        const sortedByAlphabet = sortAlphabetically(candidates);
        return sortedByAlphabet.sort(function(a, b) {
          const textA = `${a.CandidateSubHeading || ''}`;
          const textB = `${b.CandidateSubHeading || ''}`;
          const nameA = `${a.LastName}, ${a.FirstName}`;
          const nameB = `${b.LastName}, ${b.FirstName}`;

          if (sortAscending) {
            return textA < textB ? 1 : textA > textB ? -1 : nameA < nameB ? 1 : nameB < nameA ? -1 : 0;
          } else {
            return textA < textB ? -1 : textA > textB ? 1 : nameA > nameB ? 1 : nameB > nameA ? -1 : 0;
          }
        });
      });
    } else {
      setCandidateInfo((prevState) => {
        const candidates = incomingCandidates ?? prevState;
        const sortedByAlphabet = sortAlphabetically(candidates);
        return sortedByAlphabet.sort(function(a, b) {
          const textA = `${a.MedicalSchoolOfGraduation || ''}`;
          const textB = `${b.MedicalSchoolOfGraduation || ''}`;
          const nameA = `${a.LastName}, ${a.FirstName}`;
          const nameB = `${b.LastName}, ${b.FirstName}`;
          if (sortAscending) {
            return textA < textB ? 1 : textA > textB ? -1 : nameA < nameB ? 1 : nameB < nameA ? -1 : 0;
          } else {
            return textA < textB ? -1 : textA > textB ? 1 : nameA > nameB ? 1 : nameB > nameA ? -1 : 0;
          }
        });
      });
    }

    setCurrentCandidateSortType(candidateSortTypes.University);
  };

  const sortAlphabetically = (candidates, isIncompleteCandidates) => {
    return candidates.sort(function(a, b) {
      if (a.Candidates4DateNameLast < b.Candidates4DateNameLast) {
        return -1;
      } else if (a.Candidates4DateNameLast > b.Candidates4DateNameLast) {
        return 1;
      }
      return 0;
    });
  };

  const sortByEvalStatus = (incomingCandidates, isIncompleteCandidates, direction = 'asc') => {
    const sortAscending = direction === 'asc';

    if (isIncompleteCandidates) {
      setIncompleteCandidates((prevState) => {
        const candidates = clone(incompleteCandidates);
        const sortedByAlphabet = sortAlphabetically(candidates);
        const valueToReturn = sortedByAlphabet.sort(function(a, b) {
          const aStatus = a.Status || '';
          const bStatus = b.Status || '';
          if (aStatus.split(' ')[0].length < bStatus.split(' ')[0].length) {
            if (sortAscending) {
              return -1;
            } else {
              return 1;
            }
          }
          if (aStatus.split(' ')[0].length > bStatus.split(' ')[0].length) {
            if (sortAscending) {
              return 1;
            } else {
              return -1;
            }
          }
          return 0;
        });
        return valueToReturn;
      });
    } else {
      setCandidateInfo((prevState) => {
        const candidates = incomingCandidates ?? prevState;
        const sortedByAlphabet = sortAlphabetically(candidates);
        const valueToReturn = sortedByAlphabet.sort(function(a, b) {
          const aStatus = a.Status || '';
          const bStatus = b.Status || '';
          if (aStatus.split(' ')[0].length < bStatus.split(' ')[0].length) {
            if (sortAscending) {
              return -1;
            } else {
              return 1;
            }
          }
          if (aStatus.split(' ')[0].length > bStatus.split(' ')[0].length) {
            if (sortAscending) {
              return 1;
            } else {
              return -1;
            }
          }
          return 0;
        });
        return valueToReturn;
      });
    }

    setCurrentCandidateSortType(candidateSortTypes.EvalStatus);
  };

  const sortCandidates = (
    type,
    isIncompleteCandidates = false,
    listToUse,
    direction = sortCandAscending ? 'asc' : 'desc',
  ) => {
    if (direction != null) {
      setSortCandAscending(direction === 'asc' ? true : false);
    }

    if (type === candidateSortTypes.Alphabetical) {
      sortCandidatesAlphabetically(listToUse || null, isIncompleteCandidates, direction);
    } else if (type === candidateSortTypes.EvalStatus) {
      sortByEvalStatus(listToUse || null, isIncompleteCandidates, direction);
    } else if (type === candidateSortTypes.University) {
      sortByUniversity(listToUse || null, isIncompleteCandidates, direction);
    }
  };

  // Get candidate list for Candidates tab
  const getCandidateForDate = (pk_Department, pk_Season, pk_InterviewDate) => {
    //if the admin is trying to access the evaluations and a user hasn't been selected don't get candidates
    if (isAdmin && !pk_User) {
      return;
    }

    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/user/scheduledate/candidates',
          {
            pk_Department: pk_Department || dContext.department.pk_Department,
            pk_InterviewDate: pk_InterviewDate || reduxDate.pk_InterviewDate,
            pk_User,
            isAdmin,
            allCandidatesForDate: true,
            mode: isPrescreen || isPrescreenRef.current ? 'Prescreen' : 'Evaluation',
          },
          formatBearerToken(token),
        )
          .then((result) => {
            sortCandidates(currentCandidateSortType, false, result.data);
          })
          .catch((err) => {
            console.log('err getting candidates for date: ', err);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }

        console.log('error getting token: ', err);
      });
  };

  // Get candidate list for Incomplete
  const getNeedsAttention = () => {
    if (!dContext || !dContext.department) {
      return;
    }
    const payload = {
      pk_Department: dContext.department.pk_Department,
      pk_Season: dContext.season.pk_Season,
      // pk_InterviewDate: date.pk_InterviewDate,
      mode: isPrescreen ? 'Prescreen' : 'Evaluation',
      attentionNeeded: 1,
    };

    if (selectedEvaluator && selectedEvaluator.pk_User) {
      payload.pk_User = selectedEvaluator.pk_User;
      payload.isAdmin = 1;
    }

    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic('department/season/user/scheduledate/candidates', payload, formatBearerToken(token))
          .then((res) => {
            sortCandidates(currentCandidateSortType, true, res.data);
          })
          .catch((err) => {
            console.log('err: ', err);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  // Gets schedule
  const getInfo = (quietly) => {
    // console.log('getInfo firing! quietly: ', quietly);
    // console.log('getInfo reduxDateRef.current: ', clone(reduxDateRef.current));
    // console.log('getInfo pk_User: ', pk_User);
    // console.log('getInfo isAdmin: ', isAdmin);
    // console.log('getInfo reduxDate: ', reduxDate);

    if (!dContext || !dContext.season || !dContext.department) {
      // console.log('no dContext data!');
      return;
    }

    if (!reduxDateRef.current) {
      // console.log('no reduxDate!');
      setIsLoading(false);
      setIsQuietLoading(false);
      setMinutesToUpdateToDisplay(minutes);
      minutesToUpdateToDisplayRef.current = minutes;
      return;
    }

    if (isPrescreen || isPrescreenRef.current) {
      // console.log("isPrescreen, don't fetch");

      setSchedule(null);
      getPrescreen(quietly);
      setMinutesToUpdateToDisplay(minutes);
      minutesToUpdateToDisplayRef.current = minutes;
      return;
    }

    // if admin is accessing this page and no user is selected, don't fetch
    if (isAdmin && !pk_User) {
      setIsLoading(false);
      setIsQuietLoading(false);
      setMinutesToUpdateToDisplay(minutes);
      minutesToUpdateToDisplayRef.current = minutes;
      return;
    }

    if (reduxDateRef.current && reduxDateRef.current.pk_InterviewDate) {
      const { pk_InterviewDate } = reduxDateRef.current;

      const newPayload = {
        pk_Department: dContext.department.pk_Department,
        pk_InterviewDate: pk_InterviewDate,
        pk_User,
        isAdmin,
      };

      let payloadHasDifference = false;

      Object.keys(newPayload).forEach((key) => {
        if (newPayload[key] != oldGetInfoPayload[key]) {
          payloadHasDifference = true;
        }
      });

      if (!payloadHasDifference && !quietly) {
        // console.log("getInfo cancelled, payload hasn't changed");
        setIsLoading(false);
        setMinutesToUpdateToDisplay(minutes);
        minutesToUpdateToDisplayRef.current = minutes;
        return;
      } else {
        oldGetInfoPayload = newPayload;
      }

      if (!quietly) {
        setIsLoading(true);
      } else {
        setIsQuietLoading(true);
      }
      setError(false);
      // console.log('getInfo firing');
      // setSchedule(null);

      getTokenSilently()
        .then((token) => {
          fetchDataAgnostic(
            'department/season/interviewDate/user/schedule',
            { pk_Department: dContext.department.pk_Department, pk_InterviewDate: pk_InterviewDate, pk_User, isAdmin },
            formatBearerToken(token),
          )
            .then((result) => {
              if (
                (reduxDateRef.current && reduxDateRef.current.pk_InterviewDate != pk_InterviewDate) ||
                pk_User != pk_UserRef.current
              ) {
                return;
              }

              setMinutesToUpdateToDisplay(minutes);
              minutesToUpdateToDisplayRef.current = minutes;

              const { flexEvents = [] } = result.data || {};

              if (result.data) {
                if (result.data.virtualMeetingMode) {
                  setVirtualMeetingMode(result.data.virtualMeetingMode);
                }

                if (result.data.firstBlockEarlyJoinInMinutes != null) {
                  setFirstBlockEarlyJoinInMinutes(result.data.firstBlockEarlyJoinInMinutes);
                }
              }

              momentizeUnavailableTimes(result);
              if (result.data && result.data && result.data.metaData) {
                const {
                  EnableEvaluationQuestionnaire,
                  EnableHiddenStatus,
                  EnableLockedStatus,
                  EnablePrescreenQuestionnaire,
                  MainRoomURL,
                  MainRoomURLDetails,
                } = result.data.metaData;
                setLockedStatuses({
                  EnableEvaluationQuestionnaire,
                  EnableHiddenStatus,
                  EnableLockedStatus,
                  EnablePrescreenQuestionnaire,
                });
                setAllDayRoom(MainRoomURL);
                setMainRoomUrlDetails(MainRoomURLDetails);
              }

              result.data.body.forEach((item) => {
                const { EndTime, StartTime } = item;
                item.EndTimeAsMoment = moment(EndTime);
                item.StartTimeAsMoment = moment(StartTime);
              });

              setAlarms(result.data.alertsResults);
              setSchedule((prevState) => {
                const arrayEqual = isArrayEqual(result.data.body, prevState, ['StartTime', 'EndTime']);
                const { dateBefore, dateAfter, sameDates } = processAdjacentDates(adjacentDatesRef.current);

                const newIndexesWithDifferences =
                  isEmbedded || previousDateIsDifferent
                    ? []
                    : getArrayIndexesWithDifferencesViaTimeSlot(
                        result.data.body || [],
                        prevState || [],
                        ['Candidates'],
                        [],
                        activeTabRef.current === '1' ? [] : indexesWithDifferences,
                      );

                if (newIndexesWithDifferences && newIndexesWithDifferences.length > 1) {
                  setIndexesWithDifferences(newIndexesWithDifferences); // Only overwrite existing, if there are new changes. Only by clicking OK in SWAL should we remove highlight
                  setHighlightIsFading(false);
                  if (activeTabRef.current === '1' && !highlightIsFadingRef.current) {
                    setHighlightIsFading(true);
                    Swal.fire({
                      title: 'Schedule has been updated',
                      text: 'Changes are highlighted!',
                    }).then(() => {
                      setShowRed(true);
                      setTimeout(() => {
                        setShowRed(false);
                        setIndexesWithDifferences([]);
                        setHighlightIsFading(false);
                      }, 10 * 1000);
                    });
                  } else {
                    alert.info('Schedule has been updated!');
                  }
                }
                setDifferences(arrayEqual);

                const newBody = result.data.body;

                // if (newBody.length > 0) {
                if (dateBefore) {
                  newBody.splice(0, 0, { isRedirect: true, interviewDate: dateBefore, type: 'before' });
                }

                if (dateAfter) {
                  newBody.push({ isRedirect: true, interviewDate: dateAfter, type: 'after' });
                }

                if (sameDates && sameDates.length > 0) {
                  newBody.push({ isRedirect: true, interviewDates: sameDates, type: 'same' });
                }

                newBody.forEach((item) => {
                  if (item.isFlexEvent) {
                    item.FlexEvent = flexEvents.find((f) => f.pk_FlexEvent === item.pk_FlexEvent);
                  }
                });
                return newBody;
              });
              setIsLoading(false);
              setIsQuietLoading(false);
              minutesToUpdateToDisplayRef.current = minutes;
              // setTimeout(() => {
              //   setError(true);
              // }, 6000);
            })
            .catch((err) => {
              console.log('getInfo error: ', err);
              setError(true);
              // setSchedule(null);
              setIsLoading(false);
              setIsQuietLoading(false);
              minutesToUpdateToDisplayRef.current = minutes;
            });

          getCandidateForDate(dContext.department.pk_Department, dContext.season.pk_Season, pk_InterviewDate, token);
          // getNeedsAttention();
        })
        .catch((err) => {
          if (err.message === 'Login required') {
            loginWithRedirect();
          }
        });
    } else {
      // console.log("reduxDateRef.current doesn't have pk_InterviewDate");
    }
  };

  const updateAttachedAdjacentDates = (body, newAdjacentDates) => {
    const { dateBefore, dateAfter, sameDates } = processAdjacentDates(newAdjacentDates);
    const newBody = clone(body);

    if (dateBefore) {
      newBody.splice(0, 0, { isRedirect: true, interviewDate: dateBefore, type: 'before' });
    }

    if (dateAfter) {
      newBody.push({ isRedirect: true, interviewDate: dateAfter, type: 'after' });
    }

    if (sameDates && sameDates.length > 0) {
      newBody.push({ isRedirect: true, interviewDates: sameDates, type: 'same' });
    }
    return newBody;
  };

  const processAdjacentDates = (forcedAdjacentDates) => {
    let dateBefore = null;
    let dateAfter = null;
    let sameDates = [];
    const adjacentDatesActual = forcedAdjacentDates ? forcedAdjacentDates : adjacentDates;

    const dateAvailable = reduxDate || {};
    if (adjacentDatesActual == null || adjacentDatesActual.length <= 0) {
      return { dateBefore, dateAfter, sameDates };
    }

    const currentDateIndex = adjacentDatesActual.findIndex((a) => {
      return a.pk_InterviewDate === dateAvailable.pk_InterviewDate;
    });

    if (currentDateIndex >= 0) {
      let x = currentDateIndex;
      while (x > 0 && dateBefore == null) {
        const tryDate = adjacentDatesActual[x - 1];
        const dStart = moment(`${tryDate.DateOfInterview} ${tryDate.StartTime}`);
        const dateStart = moment(`${dateAvailable.DateOfInterview} ${dateAvailable.StartTime}`);
        if (dStart.isBefore(dateStart)) {
          dateBefore = tryDate;
        } else if (tryDate.pk_InterviewDate != dateAvailable.pk_InterviewDate && dStart.isSame(dateStart)) {
          const sameDateContains = sameDates.find((d) => {
            return d.pk_InterviewDate === tryDate.pk_InterviewDate;
          });

          if (!sameDateContains) {
            sameDates.push(tryDate);
          }
        }
        x--;
      }

      x = currentDateIndex;

      while (x < adjacentDatesActual.length - 1 && dateAfter == null) {
        const tryDate = adjacentDatesActual[x + 1];

        const dStart = moment(`${tryDate.DateOfInterview} ${tryDate.StartTime}`);
        const dateStart = moment(`${dateAvailable.DateOfInterview} ${dateAvailable.StartTime}`);

        if (dStart.isAfter(dateStart)) {
          dateAfter = tryDate;
        } else if (tryDate.pk_InterviewDate != dateAvailable.pk_InterviewDate && dStart.isSame(dateStart)) {
          const sameDateContains = sameDates.find((d) => {
            return (
              d.pk_InterviewDate != dateAvailable.pk_InterviewDate && d.pk_InterviewDate === tryDate.pk_InterviewDate
            );
          });

          if (!sameDateContains) {
            sameDates.push(tryDate);
          }
        }
        x++;
      }
    }

    return { dateBefore, dateAfter, sameDates };
  };

  const checkIfStatusNeedsChangingToModify = (options) => {
    if (!dContext || !dContext.season || !dContext.department) {
      return;
    }
    const textValue = 'In progress';
    if (
      candidateEvaluationStatus &&
      candidateEvaluationStatus.Status &&
      (candidateEvaluationStatus.Status.toUpperCase() === 'COMPLETE' ||
        candidateEvaluationStatus.Status.toUpperCase() === 'UNABLE TO EVALUATE')
    ) {
      return Swal.fire({
        title: 'Do you want to modify this evaluation?',
        text: `Status will be changed from "Complete" to "${textValue}"`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(48, 133, 214)',
        confirmButtonText: `Modify`,
        cancelButtonColor: 'rgb(221, 51, 51)',
      }).then(async (result) => {
        if (result.value) {
          const token = await getTokenSilently();
          const dataChanged = await putData(
            'department/season/candidate/evaluation/status',
            {
              pk_Department: dContext.department.pk_Department,
              pk_Season: dContext.season.pk_Season,
              pk_User,
              selectedCandidateId: selectedCandidate.pk_Candidate,
              isAdmin,
              status: 'InProgress',
              mode: isPrescreen ? 'Prescreen' : 'Evaluation',
            },
            null,
            formatBearerToken(token),
          );

          await getCandidateStatus(selectedCandidate.pk_Candidate, token);

          return true;
        }
        return false;
      });
    } else {
      return true;
    }
  };

  //TODO: deprecate this
  const requestZoomMeeting = (item) => {
    return;
  };

  const requestVirtualMeetingEvaluator = (item) => {
    if (!dContext || !dContext.season || !dContext.department) {
      return;
    }
    const { pk_InterviewDate } = reduxDate;
    const { pk_ScheduleAssignment, pk_ScheduleBlock, pk_Timeslot } = item;
    return getTokenSilently()
      .then((token) => {
        return fetchDataAgnostic(
          'department/season/block/evaluator/meeting',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_ScheduleBlock,
            pk_InterviewDate,
            pk_Timeslot,
            pk_ScheduleAssignment,
            pk_User,
          },
          formatBearerToken(token),
        )
          .then((result) => {
            return result;
          })
          .catch((err) => {
            return null;
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getCandidateStatus = (id, token) => {
    return fetchDataAgnostic(
      'department/candidate/evalStatus',
      {
        pk_Department: dContext.department.pk_Department,
        pk_Candidate: id,
        pk_User,
        isAdmin,
        mode: isPrescreen ? 'Prescreen' : 'Evaluation',
      },
      formatBearerToken(token),
    )
      .then((result) => {
        setCandidateEvaluationStatus(result.data);
      })
      .catch((err) => {});
  };

  const getCandidateDetails = (id, quietly) => {
    if (!dContext || !dContext.season || !dContext.department) {
      return;
    }
    if (!quietly) {
      setSelectedCandidate('fetching');
    }
    const myTimeSlot = pk_TimeSlotRef.current;

    getTokenSilently()
      .then((token) => {
        let candidate = fetchDataAgnostic(
          'department/candidate',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Candidate: id,
            context: 'evaluator',
            // pk_User, isAdmin
          },
          formatBearerToken(token),
        );

        getCandidateStatus(id, token);

        Promise.all([candidate])
          .then((result) => {
            if (myTimeSlot == pk_TimeSlotRef.current || !selectedTimeSlot) {
              setSelectedCandidate(result[0].data);
            }
          })
          .catch((err) => {
            console.log('get candidate err: ', err);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const updateSelectedTimeSlotFromSelectedCandidate = (candidateToFind) => {
    let newTimeSlot = null;
    if (schedule) {
      let scheduleIndex = 0;

      while (scheduleIndex < schedule.length && newTimeSlot == null) {
        const row = schedule[scheduleIndex];
        const { Candidates } = row;

        if (Candidates) {
          let candidateIndex = 0;
          while (candidateIndex < Candidates.length && newTimeSlot == null) {
            const c = Candidates[candidateIndex];
            if (newTimeSlot == null && c.pk_Candidate == candidateToFind) {
              newTimeSlot = row;
            }
            candidateIndex++;
          }
        }
        scheduleIndex++;
      }
    }

    if (newTimeSlot) {
      setSelectedTimeSlot(clone(newTimeSlot));
    }
  };

  const getCandidateEvaluation = async (id, forcedDate) => {
    if (!dContext || !dContext.season || !dContext.department) {
      return;
    }

    // console.log('forcedDate: ', forcedDate);
    // console.log('reduxDate: ', reduxDate.pk_InterviewDate);
    // console.log('isPrescreen: ', isPrescreen);
    let dateToUse = forcedDate ? forcedDate : isPrescreen ? null : reduxDate ? reduxDate.pk_InterviewDate : null;

    const myTimeSlot = pk_TimeSlotRef.current;

    return getTokenSilently()
      .then((token) => {
        return fetchDataAgnostic(
          'department/season/candidate/evaluation',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Candidate: id || selectedCandidateId,
            pk_Season: dContext.season.pk_Season,
            // pk_InterviewDate: reduxDate.pk_InterviewDate,
            pk_User,
            isAdmin,
            mode: isPrescreen ? 'Prescreen' : 'Evaluation',
            pk_InterviewDate: dateToUse,
          },
          formatBearerToken(token),
        )
          .then((result) => {
            if (myTimeSlot == pk_TimeSlotRef.current) {
              if (result.data && result.data.Status && result.data.Status.Status) {
                setCandidateEvaluationStatus(result.data.Status);
                setDNRStatus(result.data.Status.EnableDoNotRank);
              }
              setCandidateEvaluation(result.data);
              setCurrentlGettingEval(false);
            }

            return result;
          })
          .catch((err) => {
            return err;
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getCandidate = (id, forcedDate) => {
    // don't get the user if it's already the one selected
    if (selectedCandidate && selectedCandidate.pk_Candidate === id) return;

    getCandidateDetails(id);
    getCandidateEvaluation(id, forcedDate);
    setSelectedCandidateId(id);
  };

  const getDataTypes = () => {
    if (!dContext || !dContext.season || !dContext.department) {
      return;
    }
    // /department/dataTypes?pk_Department=6
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/dataTypes',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((result) => {
            const { data } = result;
            // filter by displayed properties
            const filteredObject = Object.keys(data)
              // .filter((key) => data[key].DisplayToEvaluator)
              .filter((key) => {
                const item = data[key];
                return item.Enabled; //&& (item.Type == 1 || item.Type == 3);
              })
              .reduce((res, key) => ((res[key] = data[key]), res), {});
            setDataTypes(Object.values(filteredObject));
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const checkInactivity = (forceLogout) => {
    const now = moment();
    const lastActivity = localStorage.getItem('lastActivity');
    const schedStart = scheduleStartTime.current;
    const schedEnd = scheduleEndTime.current;
    if (!schedStart || !schedEnd) {
      return;
    }

    const lastActivityMoment = lastActivity ? moment(lastActivity) : null;
    const currentInactivityLength = now.diff(lastActivityMoment, 'minutes');
    const isMeetingInProgress = now.isSameOrAfter(schedStart) && now.isSameOrBefore(schedEnd);

    if (
      !isMeetingInProgress &&
      !isEmbedded &&
      (!lastActivity || (lastActivity && currentInactivityLength > inactivityWait && !hasFiredInactivityPopup))
    ) {
      hasFiredInactivityPopup = true;
      // const autoLogoutTimeout = setTimeout(() => {
      //   logout();
      // }, 40000);

      if (forceLogout) {
        sessionStorage.removeItem('roleChoice');
        logout();
      } else {
        Swal.fire({
          title: 'Inactivity Detected X',
          text: 'Are you still there?',
          showDenyButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No, Log me out',
          denyButtonColor: 'red',
          timer: 30000,
          timerProgressBar: true,
        }).then((res) => {
          hasFiredInactivityPopup = false;
          if (res.dismiss === Swal.DismissReason.timer) {
            logout();
            sessionStorage.removeItem('roleChoice');
          } else if (res.isConfirmed) {
            // YES STILL HERE
            // clearTimeout(autoLogoutTimeout);
          } else {
            // logout
            logout();
            sessionStorage.removeItem('roleChoice');
          }
        });
      }
    } else if (isMeetingInProgress) {
      localStorage.setItem('lastActivity', now.toISOString());
    }
  };

  const renderWarningBanner = () => {
    if (
      selectedEvaluator &&
      loggedInUser &&
      selectedEvaluator.pk_User !== loggedInUser.pk_User &&
      selectedEvaluator.Auth0Email !== loggedInUser.email
    ) {
      const { UserFirst = '', UserLast = '' } = selectedEvaluator;
      const userName = `${UserFirst} ${UserLast}`;

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'center',
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor: '#f0ad4e',
            justifyContent: 'center',
          }}
        >
          <span>
            You are currently acting on behalf of <b>{` ${userName}`}</b>.
          </span>
        </div>
      );
    } else if (
      selectedEvaluator &&
      loggedInUser &&
      selectedEvaluator.pk_User == loggedInUser.pk_User &&
      selectedEvaluator.Auth0Email == loggedInUser.email
    ) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'center',
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor: '#5cb85c',
            justifyContent: 'center',
            // fontWeight: 'bold',
          }}
        >
          You are currently acting on behalf of yourself.
        </div>
      );
    } else {
      return <br />;
    }
  };

  // if there are no users don't show loading icon. Just display no results
  if (isLoading) {
    return (
      <div
        style={{
          width: `100%`,
          height: '50vh',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ height: 100, justifyContent: 'center', alignItems: 'center' }}>
          <Loading
            options={{
              labelText: 'Loading. . .',
              labelStyle: { fontSize: 15, textAlign: 'center', fontWeight: 'bold' },
            }}
          />
        </div>
      </div>
    );
  }

  const handleMeetingClick = async (item) => {
    setDisableJoinMeetingButton(true);

    const {
      VirtualMeetingMode,
      isCustomMeeting,
      CustomMeetingUrl,
      isScheduleBlock,
      CustomUrl,
      isFlexEvent,
      VirtualRoomType,
      UrlType,
    } = item;

    if (isScheduleBlock) {
      const response = await requestVirtualMeetingEvaluator(item);
      const { data } = response;
      const { DialInNumbers, Duration, IsActive, JoinURL, MeetingId, Password, StartTime, StartUrl, Topic } = data;
      // handleZoomClick(JoinURL, DialInNumbers, Password);
      handleZoomClick({ url: JoinURL, mobileNumbers: DialInNumbers, password: Password, meetingId: MeetingId });
      setDisableJoinMeetingButton(false);
      return;
    }

    let urlToUse = allDayRoom;
    if (isCustomMeeting) {
      if (VirtualRoomType === 'Custom' && CustomMeetingUrl) {
        urlToUse = CustomMeetingUrl;
      }
    }

    if (isFlexEvent && UrlType) {
      if (UrlType === 'AllDay') {
      } else {
        urlToUse = CustomUrl;
      }
    }

    if (urlToUse) {
      window.open(urlWithHttpProtocol(urlToUse), '_blank').focus();
    } else {
      Swal.fire('Error!', 'No link to join. No event has been created. Please contact your coordinator.', 'error');
    }

    setDisableJoinMeetingButton(false);
  };

  if (isMobile) {
    return (
      <Col>
        <form id="zoom_form_eval" target="_blank"></form>
        {renderMeetingDetailsCard({
          showMeetingCard,
          setShowMeetingCard,
          meetingLink,
          setCopied,
          copied,
          zoomInfo,
          zoomPassword,
          mainRoomDescription,
          meetingId,
          nonZoomInfo,
        })}
        <MobileComponent
          adjacentDates={adjacentDates}
          alarms={alarms}
          allDayRoom={allDayRoom}
          candidateEvaluation={candidateEvaluation}
          candidateEvaluationStatus={candidateEvaluationStatus}
          candidateInfo={candidateInfo}
          candidateSortTypes={candidateSortTypes}
          checkIfEvalsAreLocked={checkIfEvalsAreLocked}
          checkIfStatusNeedsChangingToModify={checkIfStatusNeedsChangingToModify}
          currentCandidateSortType={currentCandidateSortType}
          currentlyGettingEval={currentlyGettingEval}
          dataTypes={dataTypes}
          date={reduxDate}
          dContext={dContext}
          differences={differences}
          disableJoinMeetingButton={disableJoinMeetingButton}
          DNRStatus={DNRStatus}
          evalStatus={evalStatus}
          evaluationsLocked={evaluationsLocked}
          filterCandidateInfo={filterCandidateInfo}
          firstBlockEarlyJoin={firstBlockEarlyJoin}
          firstBlockEarlyJoinInMinutes={firstBlockEarlyJoinInMinutes}
          getCandidate={getCandidate}
          getCandidateDetails={getCandidateDetails}
          getCandidateEvaluation={getCandidateEvaluation}
          getCandidateForDate={getCandidateForDate}
          getCandidateStatus={getCandidateStatus}
          getInfo={getInfo}
          getNeedsAttention={getNeedsAttention}
          getPrescreen={getPrescreen}
          handleDateChange={handleDateChange}
          handleMeetingClick={handleMeetingClick}
          handleZoomClick={handleZoomClick}
          headerHeight={headerHeight}
          heightOfHeader={heightOfHeader}
          incompleteCandidates={incompleteCandidates}
          indexesWithDifferences={indexesWithDifferences}
          informUserMeetingIsStartingLocally={informUserMeetingIsStartingLocally}
          interviewHasPassed={interviewHasPassed}
          interviewIsToday={interviewIsToday}
          isAdmin={isAdmin}
          isEmbedded={isEmbedded}
          isLoading={isQuietLoading || isLoading}
          lockedStatuses={lockedStatuses}
          minutesToUpdate={minutesToUpdateToDisplay / 60000}
          parentActiveTab={activeTab}
          pk_User={pk_User}
          reduxDate={reduxDate}
          requestVirtualMeetingEvaluator={requestVirtualMeetingEvaluator}
          requestZoomMeeting={requestZoomMeeting}
          schedule={schedule}
          scheduleError={error}
          Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
          Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
          selectedCandidateId={selectedCandidateId}
          selectedTimeSlot={selectedTimeSlot}
          selectedCandidate={selectedCandidate}
          setAlarms={setAlarms}
          setCandidateEvaluation={setCandidateEvaluation}
          setCandidateEvaluationStatus={setCandidateEvaluationStatus}
          setDisableJoinMeetingButton={setDisableJoinMeetingButton}
          setDNRStatus={setDNRStatus}
          setEvalStatus={setEvalStatus}
          setLargePhoto={setLargePhoto}
          setParentActiveTab={setActiveTab}
          setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
          setSchedules4EvalJoinManualURL={setSchedules4EvalJoinManualURL}
          setSelectedTimeSlot={setSelectedTimeSlot}
          setSelectedCandidate={setSelectedCandidate}
          setShowLargePhoto={setShowLargePhoto}
          setShowRed={setShowRed}
          setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
          showRed={showRed}
          sortCandAscending={sortCandAscending}
          sortCandidates={sortCandidates}
          timeoutDurationInMinutes={userInformation ? userInformation.DeptSettingsEvalTimeout : 1}
          userIdOnCurrentInterview={userIdOnCurrentInterview}
          userInformation={userInformation}
          VirtualMeetingMode={virtualMeetingMode}
        />
      </Col>
    );
  } else {
    return (
      <Col>
        <form id="zoom_form_eval" target="_blank"></form>
        {renderWarningBanner()}
        {renderMeetingDetailsCard({
          showMeetingCard,
          setShowMeetingCard,
          meetingLink,
          setCopied,
          copied,
          zoomInfo,
          zoomPassword,
          mainRoomDescription,
          meetingId,
        })}
        <BrowserComponent
          adjacentDates={adjacentDates}
          alarms={alarms}
          allDayRoom={allDayRoom}
          mainRoomUrlDetails={mainRoomUrlDetails}
          candidateEvaluation={candidateEvaluation}
          candidateEvaluationStatus={candidateEvaluationStatus}
          candidateInfo={candidateInfo}
          candidateSortTypes={candidateSortTypes}
          checkIfEvalsAreLocked={checkIfEvalsAreLocked}
          checkIfStatusNeedsChangingToModify={checkIfStatusNeedsChangingToModify}
          currentCandidateSortType={currentCandidateSortType}
          currentlyGettingEval={currentlyGettingEval}
          dataTypes={dataTypes}
          dContext={dContext}
          differences={differences}
          disableJoinMeetingButton={disableJoinMeetingButton}
          DNRStatus={DNRStatus}
          evalStatus={evalStatus}
          evaluationsLocked={evaluationsLocked}
          filterCandidateInfo={filterCandidateInfo}
          firstBlockEarlyJoin={firstBlockEarlyJoin}
          firstBlockEarlyJoinInMinutes={firstBlockEarlyJoinInMinutes}
          getCandidate={getCandidate}
          getCandidateDetails={getCandidateDetails}
          getCandidateEvaluation={getCandidateEvaluation}
          getCandidateForDate={getCandidateForDate}
          getCandidateStatus={getCandidateStatus}
          getInfo={getInfo}
          getNeedsAttention={getNeedsAttention}
          getPrescreen={getPrescreen}
          handleDateChange={handleDateChange}
          handleMeetingClick={handleMeetingClick}
          handleZoomClick={handleZoomClick}
          headerHeight={headerHeight}
          heightOfHeader={heightOfHeader}
          incompleteCandidates={incompleteCandidates}
          indexesWithDifferences={indexesWithDifferences}
          informUserMeetingIsStartingLocally={informUserMeetingIsStartingLocally}
          interviewHasPassed={interviewHasPassed}
          interviewIsToday={interviewIsToday}
          isAdmin={isAdmin}
          isEmbedded={isEmbedded}
          isLoading={isQuietLoading || isLoading}
          lockedStatuses={lockedStatuses}
          minutesToUpdate={minutesToUpdateToDisplay / 60000}
          parentActiveTab={activeTab}
          pk_User={pk_User}
          reduxDate={reduxDate}
          requestVirtualMeetingEvaluator={requestVirtualMeetingEvaluator}
          requestZoomMeeting={requestZoomMeeting}
          schedule={schedule}
          scheduleError={error}
          Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
          Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
          selectedCandidateId={selectedCandidateId}
          selectedTimeSlot={selectedTimeSlot}
          selectedCandidate={selectedCandidate}
          selectedEvaluator={selectedEvaluator} // From AdminEvaluate, Admin's Evaluate tab.
          setAlarms={setAlarms}
          setCandidateEvaluation={setCandidateEvaluation}
          setCandidateEvaluationStatus={setCandidateEvaluationStatus}
          setDisableJoinMeetingButton={setDisableJoinMeetingButton}
          setDNRStatus={setDNRStatus}
          setEvalStatus={setEvalStatus}
          setLargePhoto={setLargePhoto}
          setParentActiveTab={setActiveTab}
          setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
          setSchedules4EvalJoinManualURL={setSchedules4EvalJoinManualURL}
          setSelectedTimeSlot={(item) => {
            pk_TimeSlotRef.current = item.pk_Timeslot;
            setSelectedTimeSlot(item);
          }}
          setSelectedCandidate={setSelectedCandidate}
          setShowLargePhoto={setShowLargePhoto}
          setShowRed={setShowRed}
          setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
          showRed={showRed}
          sortCandAscending={sortCandAscending}
          sortCandidates={sortCandidates}
          timeoutDurationInMinutes={userInformation ? userInformation.DeptSettingsEvalTimeout : 1}
          VirtualMeetingMode={virtualMeetingMode}
          userIdOnCurrentInterview={userIdOnCurrentInterview}
        />
      </Col>
    );
  }
};

export default MobileFork;
