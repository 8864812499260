import React, { useState, useEffect } from 'react';
import style from './ApplicantAccount.style';
import ApplicationField from '../../../ApplicationField/ApplicationField';
import DragAndDropFiles from 'Body/DragAndDropFiles/DragAndDropFiles';
import UserPhoto from 'Body/DragAndDropFiles/UserPhoto/UserPhoto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { fetchDataAgnostic, putData } from 'Services/dataApi';
import { formatBearerToken, splitCamelCase } from 'Common.functions';
import { result } from 'lodash';
import { useAlert } from 'react-alert';
import Loading from 'Body/Statuses/Loading';

const ApplicantAccount = (props) => {
  const { applicantDetails } = props;
  const { pk_Applicant, ApplicantPhotoUrl } = applicantDetails || {};
  // State variables
  const { isAuthenticated, login, getToken } = useKindeAuth();
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [sections, setSections] = useState([
    // {
    //   label: 'Core',
    //   fields: [
    //     { label: 'Current Position', dataKey: 'CurrentPosition' },
    //     { label: 'Current Institution', dataKey: 'CurrentInstitution' },
    //     { label: 'Current StreetAddress', dataKey: 'CurrentStreetAddress' },
    //     { label: 'Current City', dataKey: 'CurrentCity' },
    //     { label: 'Current State', dataKey: 'CurrentState' },
    //     { label: 'Current Zip', dataKey: 'CurrentZip' },
    //     { label: 'Current Country', dataKey: 'CurrentCountry' },
    //     { label: 'Current Cell Phone Number', dataKey: 'CurrentCellPhoneNumber' },
    //     { label: 'Current Immigration Status', dataKey: 'CurrentImmigrationStatus' },
    //     { label: 'Current Visa Type', dataKey: 'CurrentVisaType' },
    //   ],
    // },
  ]);

  const [editableSections, setEditableSections] = useState([]);

  const alert = useAlert();

  // Effect hook
  useEffect(() => {
    // if (isAuthenticated && applicantDetails && applicantDetails.pk_Applicant) {
    if (isAuthenticated) {
      getAapplicantForm(applicantDetails);
    }
  }, [isAuthenticated, applicantDetails]);

  const checkForChanges = () => {
    let hasChanges = false;
    sections.forEach((section, i) => {
      const { fields = [] } = section;
      const editableFields = editableSections[i] && editableSections[i].fields ? editableSections[i].fields : [];

      fields.forEach((field, j) => {
        const { value = '' } = field;
        const editableField = editableFields[j];
        const editableValue = editableField ? editableField.value : '';

        if (
          (!editableValue && value) ||
          (!value && editableValue) ||
          (value != null && editableValue != null && value !== editableValue)
        ) {
          hasChanges = true;
        }
      });
    });

    return hasChanges;
  };

  const getAapplicantForm = async (applicantData = {}) => {
    setIsFetching(true);
    const token = await getToken();

    fetchDataAgnostic('applicant/', { pk_Applicant }, formatBearerToken(token))
      .then((result) => {
        setIsFetching(false);
        initializeSections({
          applicantData,
          fields: result.data,
        });
        // const newFields = [];

        // Object.keys(result.data).forEach((key) => {
        //   if (keysToSkip.includes(key)) return;

        //   newFields.push({ dataKey: key, value: applicantData[key], label: splitCamelCase(key) });
        // });

        // const newSections = [{ ...sections[0], fields: newFields }];
        // setSections(newSections);
        // setEditableSections(newSections);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const initializeSections = (options) => {
    const keysToSkip = ['pk_Applicant', 'createdAt', 'modifiedAt'];
    const { applicantData, fields } = options;

    const newFields = [];

    Object.keys(fields).forEach((key) => {
      if (keysToSkip.includes(key)) return;

      newFields.push({ dataKey: key, value: applicantData[key], label: splitCamelCase(key) });
    });

    const newSections = [{ ...sections[0], fields: newFields }];
    setSections(newSections);
    setEditableSections(newSections);
  };

  const saveForm = async () => {
    const dataToSubmit = {};

    editableSections.forEach((section) => {
      section.fields.forEach((field) => {
        const { dataKey, value } = field;
        dataToSubmit[dataKey] = value;
      });
    });

    setIsSaving(true);
    const token = await getToken();
    putData('applicant/', { pk_Applicant }, { ...dataToSubmit }, formatBearerToken(token))
      .then((result) => {
        setIsSaving(false);
        alert.success('Form saved successfully');
      })
      .catch((err) => {
        setIsSaving(false);
        alert.success('Form failed to save');
        console.log('err: ', err);
      });
  };

  const hasChanges = checkForChanges();
  return (
    <div
      style={{
        ...style.simpleRow,
        width: '70%',
      }}
    >
      <div style={style.simpleColumn}>
        <div style={style.simpleRow}>
          <div style={style.simpleColumn}>
            <UserPhoto
              photoUrl={ApplicantPhotoUrl}
              isDisabled={isFetching || isSaving}
              imgStyle={{ width: 60, height: 60, objectFit: 'contain' }}
              type="Applicant"
              callback={(id, uri) => {
                if (uri) {
                  alert.success('Photo uploaded successfully');
                } else {
                  alert.error('Failed to upload photo');
                }
              }}
              placeholderImage={
                <div style={style.simpleRow}>
                  <FontAwesomeIcon icon={faUser} style={{ fontSize: 40 }} />
                </div>
              }
            />
          </div>
        </div>
        <div
          style={{
            ...style.simpleRow,
            width: '100%',
            height: 'calc(100vh - 350px)',
            overflowY: 'auto',
            minWidth: 500,
            alignItems: 'flex-start',
            marginTop: 10,
          }}
        >
          <div style={style.simpleColumn}>
            {isFetching ? (
              <div style={{ marginTop: '10vh' }}>
                {' '}
                <Loading options={{ labelText: 'Loading. . .' }} />{' '}
              </div>
            ) : (
              editableSections.map((section) => {
                return section.fields.map((field, i) => {
                  const { label, dataKey, value } = field;
                  return (
                    <ApplicationField
                      key={dataKey}
                      fieldId={dataKey}
                      label={label}
                      type="text"
                      value={value}
                      disabled={isFetching || isSaving}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const newField = { ...field, value: newValue };
                        const newFields = [...section.fields];
                        newFields[i] = newField;

                        const newSection = { ...section, fields: newFields };
                        const newSections = [...sections];
                        newSections[0] = newSection;
                        setEditableSections(newSections);
                      }}
                    />
                  );
                });
              })
            )}
          </div>
        </div>
        <div style={style.simpleRow}>
          <Button
            disabled={!hasChanges || isSaving || isFetching}
            color="success"
            onClick={() => {
              saveForm();
            }}
          >
            <b>Save</b>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApplicantAccount;
