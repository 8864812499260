import { faCaretDown, faCaretUp, faEyeSlash, faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Row, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';
import Swal from 'sweetalert2';
import { informUserEvalLocked } from '../../../../Common.functions';
import CandidatesAlphabetical from './CandidatesAlphabetical';
import Evaluation from './Evaluation';
import Portfolio from './Portfolio';
import Schedule from './Schedule';
import { connect } from 'react-redux';

const OFFSET = 65;
const OFFSET_SCROLL = 86;
const OFFSET_OLD = 201;

const verticalLine = { height: '100%', borderRight: '1px solid #000000', width: '1px' };

const EvaluateBrowser = ({
  adjacentDates,
  alarms,
  allDayRoom,
  candidateEvaluation,
  setCandidateEvaluationStatus,
  candidateEvaluationStatus,
  candidateInfo,
  candidateSortTypes,
  checkIfEvalsAreLocked,
  checkIfStatusNeedsChangingToModify,
  currentCandidateSortType,
  currentlyGettingEval,
  date,
  dataTypes,
  dContext,
  differences,
  disableJoinMeetingButton,
  DNRStatus,
  evalStatus,
  evaluationsLocked,
  firstBlockEarlyJoinInMinutes,
  filterCandidateInfo,
  firstBlockEarlyJoin,
  getCandidate,
  getCandidateDetails,
  getCandidateEvaluation,
  getCandidateStatus,
  getInfo,
  getPrescreen,
  handleDateChange,
  handleZoomClick,
  handleMeetingClick,
  headerHeight,
  heightOfHeader,
  incompleteCandidates,
  indexesWithDifferences,
  informUserMeetingIsStartingLocally,
  interviewHasPassed,
  interviewIsToday,
  isAdmin,
  isEmbedded,
  isLoading,
  lockedStatuses,
  minutesToUpdate,
  pk_User,
  postRequest,
  getCandidateForDate,
  getNeedsAttention,
  reduxDate,
  requestVirtualMeetingEvaluator,
  requestZoomMeeting,
  schedule,
  scheduleError,
  Schedules4Eval__pkUUID_Schedule,
  Schedules4EvalJoinManualURL,
  selectedCandidateId,
  selectedCandidate,
  setAlarms,
  setCandidateEvaluation,
  setDate,
  setDisableJoinMeetingButton,
  setDNRStatus,
  selectedEvaluator,
  setEvalStatus,
  setLargePhoto,
  setSelectedTimeSlot,
  setSelectedCandidate,
  selectedTimeSlot,
  setParentActiveTab,
  setSchedules4Eval__pkUUID_Schedule,
  setSchedules4EvalJoinManualURL,
  setShowLargePhoto,
  setShowRed,
  setUserIdOnCurrentInterview,
  showRed,
  sortCandAscending,
  sortCandidates,
  timeoutDurationInMinutes,
  VirtualMeetingMode,
  userIdOnCurrentInterview,
  userInfo,
  mainRoomUrlDetails,
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [hasSchedule, setHasSchedule] = useState(null);
  const [chosenCandidate, setChosenCandidate] = useState({ uniqueId: null, candidate: null });
  const [error, setError] = useState(false);
  const [candidatesAttentionNeeded, setCandidatesAttentionNeeded] = useState([]);
  const [disableScreen, setDisableScreen] = useState(false);
  const [previousStatus, setPreviousStatus] = useState(null);
  const [renderEval, setRenderEval] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [previousTab, setPreviousTab] = useState(); // the tab before going to Evaluate
  const [disableEvaluation, setDisableEvaluation] = useState(true);
  const [isPrescreen, setIsPrescreen] = useState(false);
  const [firstEvent, setFirstEvent] = useState();
  const [minutesToUpdateToDisplay, setMinutesToUpdateToDisplay] = useState(minutesToUpdate || 0);
  const [now, setNow] = useState(moment());
  const { user = {} } = userInfo || {};
  const { DisableCandidateEvaluations, HideCandidateDocuments, HideCandidateScores } = user || {};

  const toggle = (tab) => {
    if (activeTab !== tab) {
      if (tab === '3') {
        setPreviousTab(activeTab);
      }
      setActiveTab(tab);
    }
  };

  const toggleToPreviousTab = () => {
    switch (previousTab) {
      case '2':
        if (reduxDate && (reduxDate === 'Prescreen' || reduxDate.pk_InterviewDate === 'Prescreen')) {
          getPrescreen();
        } else {
          getCandidateForDate();
        }

        break;

      case '4':
        getNeedsAttention();
        setSelectedCandidate(null); // To clear the sidepanel
        break;
    }
    setActiveTab(previousTab);
    setPreviousTab(null);
  };

  useEffect(() => {
    setInterval(() => {
      setNow(moment());
    }, 1000);
  }, []);

  useEffect(() => {
    if (candidateInfo) setCandidatesAttentionNeeded(filterCandidateInfo(candidateInfo));
  }, [candidateInfo]);

  useEffect(() => {
    setParentActiveTab(activeTab);

    scrollToSelectedCandidate(activeTab);

    // if (!isLoading) {
    //   sortCandidates(currentCandidateSortType, activeTab === '4');
    // }
  }, [activeTab]);

  useEffect(() => {
    if (hasSchedule !== null) getDefaultTab();
    if (reduxDate === 'Prescreen' || (reduxDate && reduxDate.pk_InterviewDate === 'Prescreen')) {
      setIsPrescreen(true);
      toggle('2');
    } else {
      setIsPrescreen(false);
    }
  }, [reduxDate, hasSchedule]);

  useEffect(() => {
    if ((!selectedCandidate && disableEvaluation === false) || selectedCandidate === 'fetching') {
      setDisableEvaluation(true);
    } else if (selectedCandidate && selectedCandidate !== 'fetching' && disableEvaluation === true) {
      setDisableEvaluation(false);
    }
    return;
  }, [selectedCandidate]);

  useEffect(() => {
    if (schedule) {
      setHasSchedule(true);
      let newFirstItem = null;
      schedule.forEach((item) => {
        if (
          newFirstItem == null &&
          !item.isFiller &&
          !item.isRedirect
          // && item.Candidates && item.Candidates.length > 0
        ) {
          newFirstItem = item;
        }
      });
      setFirstEvent(newFirstItem);
    } else {
      setHasSchedule(false);
    }
  }, [schedule]);

  useEffect(() => {
    if (minutesToUpdate) {
      setMinutesToUpdateToDisplay(minutesToUpdate);
    }
  }, [minutesToUpdate]);

  const scrollToSelectedCandidate = (tabToGo) => {
    if (selectedCandidateId) {
      let element = null;
      if (tabToGo == '1') {
        element = document.getElementById(`mySchedule_scrollID_${selectedCandidateId}`);
      } else if (tabToGo == '2') {
        element = document.getElementById(`candidates_scrollID_${selectedCandidateId}`);
      }

      if (element) {
        element.scrollIntoView(true);
      }
    }
  };

  const getDefaultTab = () => {
    if (isPrescreen) {
      toggle('2');
      return;
    } else {
      if (hasSchedule) {
        toggle('1');
      } else {
        toggle('2');
      }
    }
  };

  const changeEvalTab = () => {
    if (checkIfEvalsAreLocked()) {
      informUserEvalLocked();
      return;
    }
    setRenderEval(false);
    setDisableScreen(true);
    toggle('3');
    return getCandidateEvaluation(selectedCandidate.pk_Candidate, selectedCandidate.pk_InterviewDate)
      .then((res) => {
        if (!res.error) {
          return true;
        } else {
          setError(true);
          return false;
        }
      })
      .finally(() => {
        setDisableScreen(false);
        setRenderEval(true);
      });
  };

  const renderSort = (isIncompleteCandidates = false) => {
    const isAlphabet = currentCandidateSortType === candidateSortTypes.Alphabetical;
    const isEval = currentCandidateSortType === candidateSortTypes.EvalStatus;
    const isUniversity = currentCandidateSortType === candidateSortTypes.University;

    return (
      <div
        className="sticky"
        style={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: '5px', borderBottom: '1px solid grey' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h6 style={{ margin: 0 }}>Sort By:</h6>
        </div>
        <div
          id="candidate_sort"
          style={{
            width: '70%',
          }}
        >
          <div
            className={`${isAlphabet ? 'candidate_sort_selected' : ''} hover_blue`}
            onClick={() => {
              let newSortCandAscending =
                currentCandidateSortType === candidateSortTypes.Alphabetical ? !sortCandAscending : sortCandAscending;

              const direction = newSortCandAscending ? 'asc' : 'desc';
              sortCandidates(candidateSortTypes.Alphabetical, isIncompleteCandidates, null, direction);
            }}
            style={{
              padding: '5px',
            }}
          >
            <h6>
              {isAlphabet && (
                <span style={{ paddingRight: '5px' }}>
                  <FontAwesomeIcon icon={sortCandAscending ? faCaretDown : faCaretUp} />
                </span>
              )}
              Alphabetical
            </h6>
          </div>
          <div
            className={`${isEval ? 'candidate_sort_selected' : ''} hover_blue`}
            onClick={() => {
              let newSortCandAscending =
                currentCandidateSortType === candidateSortTypes.EvalStatus ? !sortCandAscending : sortCandAscending;
              const direction = newSortCandAscending ? 'asc' : 'desc';

              sortCandidates(candidateSortTypes.EvalStatus, isIncompleteCandidates, null, direction);
            }}
            style={{
              paddingLeft: '5px',
              paddingRight: '5px',
            }}
          >
            <h6>
              {isEval && (
                <span style={{ paddingRight: '5px' }}>
                  <FontAwesomeIcon icon={sortCandAscending ? faCaretDown : faCaretUp} />
                </span>
              )}
              Evaluation Status
            </h6>
          </div>
          <div
            className={`${isUniversity ? 'candidate_sort_selected' : ''} hover_blue`}
            onClick={() => {
              let newSortCandAscending =
                currentCandidateSortType === candidateSortTypes.University ? !sortCandAscending : sortCandAscending;
              const direction = newSortCandAscending ? 'asc' : 'desc';
              sortCandidates(candidateSortTypes.University, isIncompleteCandidates, null, direction);
            }}
          >
            <h6>
              {isUniversity && (
                <span style={{ paddingRight: '5px' }}>
                  <FontAwesomeIcon icon={sortCandAscending ? faCaretDown : faCaretUp} />
                </span>
              )}
              University
            </h6>
          </div>
        </div>
      </div>
    );
  };

  const getEvalAndChangeTab = () => {
    if (checkIfEvalsAreLocked()) {
      informUserEvalLocked();
      return;
    }
    if (
      candidateEvaluationStatus &&
      candidateEvaluationStatus.Status &&
      candidateEvaluationStatus.Status.toUpperCase() === 'COMPLETE'
    ) {
      getInfo(true, true, false, currentCandidateSortType, sortCandAscending);
      return changeEvalTab();
    } else {
      return changeEvalTab();
    }
  };

  const renderEarlyMessage = () => {
    const earlyMessageCutOffTime = firstEvent ? moment(firstEvent.StartTimeAsMoment) : null;
    if (earlyMessageCutOffTime) {
      earlyMessageCutOffTime.subtract(firstBlockEarlyJoinInMinutes, 'minutes');
    }

    if (
      VirtualMeetingMode !== 'Disabled' &&
      earlyMessageCutOffTime &&
      // now.format('MMM DD') === earlyMessageCutOffTime.format('MMM DD') &&
      now.isBefore(earlyMessageCutOffTime)
    ) {
      let delay = earlyMessageCutOffTime.diff(now, 'hours');
      let unit = 'hrs';
      if (delay < 1) {
        delay = earlyMessageCutOffTime.diff(now, 'minutes');
        unit = 'mins';
      }
      return (
        <div style={{ backgroundColor: '#ffe900', textAlign: 'center' }}>
          <h5>
            You're early! Join meeting button will become active {firstBlockEarlyJoinInMinutes || 0} minute
            {firstBlockEarlyJoinInMinutes > 1 ? 's' : ''} before your first meeting.
            {/* Your early!. Join meeting buttons will not appear until {firstBlockEarlyJoinInMinutes || 0} minutes
          before your first meeting. */}
          </h5>
        </div>
      );
    }
  };

  const renderTabContents = () => {
    return (
      <TabContent activeTab={activeTab} style={{ width: '100%' }}>
        {hasSchedule && !isPrescreen && (
          <TabPane tabId="1">
            {renderEarlyMessage()}
            <Schedule
              activeTab={activeTab}
              adjacentDates={adjacentDates}
              candidateInfo={candidateInfo}
              alarms={alarms}
              allDayRoom={allDayRoom}
              mainRoomUrlDetails={mainRoomUrlDetails}
              chosenCandidate={chosenCandidate}
              date={date}
              dContext={dContext}
              differences={differences}
              disableJoinMeetingButton={disableJoinMeetingButton}
              firstEvent={firstEvent}
              firstBlockEarlyJoin={firstBlockEarlyJoin}
              getCandidate={getCandidate}
              handleDateChange={handleDateChange}
              handleZoomClick={handleZoomClick}
              handleMeetingClick={handleMeetingClick}
              indexesWithDifferences={indexesWithDifferences}
              informUserMeetingIsStartingLocally={informUserMeetingIsStartingLocally}
              interviewIsToday={interviewIsToday}
              isEmbedded={isEmbedded}
              postRequest={postRequest}
              reduxDate={reduxDate}
              requestVirtualMeetingEvaluator={requestVirtualMeetingEvaluator}
              requestZoomMeeting={requestZoomMeeting}
              schedule={schedule}
              Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
              Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
              selectedCandidate={selectedCandidate}
              setAlarms={setAlarms}
              setChosenCandidate={setChosenCandidate}
              setDate={setDate}
              setDisableJoinMeetingButton={setDisableJoinMeetingButton}
              setSelectedTimeSlot={setSelectedTimeSlot}
              setSelectedCandidate={setSelectedCandidate}
              selectedTimeSlot={selectedTimeSlot}
              setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
              setSchedules4EvalJoinManualURL={setSchedules4EvalJoinManualURL}
              setShowRed={setShowRed}
              setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
              showRed={showRed}
              userIdOnCurrentInterview={userIdOnCurrentInterview}
              virtualMeetingMode={VirtualMeetingMode}
            />
          </TabPane>
        )}
        <TabPane tabId="2">
          {renderSort()}
          <CandidatesAlphabetical
            isPrescreen={isPrescreen}
            candidateInfo={candidateInfo}
            chosenCandidate={chosenCandidate}
            disableJoinMeetingButton={disableJoinMeetingButton}
            getCandidate={getCandidate}
            handleZoomClick={handleZoomClick}
            interviewIsToday={interviewIsToday}
            postRequest={postRequest}
            requestZoomMeeting={requestZoomMeeting}
            Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
            Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
            selectedCandidate={selectedCandidate}
            setChosenCandidate={setChosenCandidate}
            setDisableJoinMeetingButton={setDisableJoinMeetingButton}
            setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
            setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
            userIdOnCurrentInterview={userIdOnCurrentInterview}
            hasSchedule={hasSchedule}
          />
        </TabPane>
        <TabPane tabId="3">
          {!disableEvaluation && renderEval && !evaluationsLocked && (
            <Evaluation
              adjacentDates={adjacentDates}
              candidateEvaluation={candidateEvaluation}
              candidateInfo={candidateInfo}
              candidateEvaluationStatus={candidateEvaluationStatus}
              selectedCandidateId={selectedCandidateId}
              reduxDate={reduxDate}
              timeoutDurationInMinutes={timeoutDurationInMinutes}
              evalTab="3"
              candidateSortTypes={candidateSortTypes}
              isAdmin={isAdmin}
              getCandidateStatus={getCandidateStatus}
              currentCandidateSortType={currentCandidateSortType}
              DNRStatus={DNRStatus}
              evaluationsLocked={evaluationsLocked}
              pk_User={pk_User}
              setCandidateEvaluationStatus={setCandidateEvaluationStatus}
              setDNRStatus={setDNRStatus}
              schedule={schedule}
              checkIfStatusNeedsChangingToModify={checkIfStatusNeedsChangingToModify}
              setDisableScreen={setDisableScreen}
              date={date}
              toggleToPreviousTab={toggleToPreviousTab}
              toggleToSchedule={() => {
                if (schedule && schedule.length) {
                  setActiveTab('1');
                } else {
                  setActiveTab('2');
                }
              }}
              setEvalStatus={setEvalStatus}
              getInfo={getInfo}
              evalStatus={evalStatus}
              selectedCandidate={selectedCandidate}
              setCandidateEvaluation={setCandidateEvaluation}
              typeOfEvent="mousemove"
              activeTab={activeTab}
              currentlyGettingEval={currentlyGettingEval}
              getCandidateEvaluation={getCandidateEvaluation}
              Status4SelectedDNR={
                candidateEvaluation && candidateEvaluation.Status ? candidateEvaluation.Status.EnableDoNotRank : false
              }
            />
          )}
        </TabPane>
        {hasSchedule && !isPrescreen && incompleteCandidates && incompleteCandidates.length > 0 && (
          <TabPane tabId="4">
            {renderSort(true)}
            <Row>
              <Col md="12">
                <CandidatesAlphabetical
                  isPrescreen={isPrescreen}
                  isIncompleteCandidates={true}
                  candidateInfo={incompleteCandidates}
                  chosenCandidate={chosenCandidate}
                  disableJoinMeetingButton={disableJoinMeetingButton}
                  getCandidate={getCandidate}
                  handleZoomClick={handleZoomClick}
                  hasSchedule={hasSchedule}
                  hideShowMeetingButton={true}
                  interviewIsToday={interviewIsToday}
                  postRequest={postRequest}
                  requestZoomMeeting={requestZoomMeeting}
                  Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
                  Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
                  selectedCandidate={selectedCandidate}
                  setChosenCandidate={setChosenCandidate}
                  setDisableJoinMeetingButton={setDisableJoinMeetingButton}
                  setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
                  setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
                  userIdOnCurrentInterview={userIdOnCurrentInterview}
                />
              </Col>
            </Row>
          </TabPane>
        )}
      </TabContent>
    );
  };

  if (!pk_User && isAdmin) {
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 290px)',
          fontWeight: 'bold',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        No Evaluator selected
      </div>
    );
  }

  if ((!candidateInfo || candidateInfo.length < 0) && !hasSchedule) {
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 290px)',
          fontWeight: 'bold',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        No Schedule Data
      </div>
    );
  }

  return (
    <Row>
      <Col sm="8" className="frame tab_wrapper">
        <div
          className="overlay"
          style={{
            position: 'absolute',
            visibility: disableScreen ? 'visible' : 'hidden',
            top: 74,
            width: 'calc(100% - 30px)',
            height: 'calc(100% - 74px)',
            backgroundColor: 'rgba(5, 5, 5, 0.5)',
            zIndex: 15,
          }}
        >
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            color="#fff"
            size={'3x'}
            style={{ position: 'relative', left: '50%', top: '50%' }}
          />
        </div>
        {date === 'Prescreen' || reduxDate === 'Prescreen' ? null : (
          <div
            style={{ fontSize: 10, fontWeight: 'bold', color: scheduleError ? 'red' : !isLoading ? 'green' : 'black' }}
          >
            {isLoading ? (
              'Updating data . . .'
            ) : scheduleError ? (
              `Error Updating data! Retrying in ${
                minutesToUpdateToDisplay > 1
                  ? `${minutesToUpdateToDisplay.toFixed(0)}min${minutesToUpdateToDisplay.toFixed(0) > 1 ? 's' : ''}`
                  : `${(minutesToUpdateToDisplay * 60).toFixed(0)}s`
              }. . .`
            ) : (
              <br />
            )
            // `Updated schedule! Next update in ${
            //   minutesToUpdateToDisplay > 1
            //     ? `${minutesToUpdateToDisplay.toFixed(0)}min${minutesToUpdateToDisplay.toFixed(0) > 1 ? 's' : ''}`
            //     : `${(minutesToUpdateToDisplay * 60).toFixed(0)}s`
            // }. . .`
            }
          </div>
        )}
        <div
          style={{
            border: '1px solid #000000',
          }}
        >
          <div>
            <Row style={{ width: '100%', top: '0', zIndex: 10, margin: 0 }} className="sticky">
              <Col md="12" style={{ padding: '0' }}>
                <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'space-evenly' }}>
                  {hasSchedule && !isPrescreen && (
                    <Fragment>
                      <div
                        className={activeTab === '1' ? 'selectedTab' : 'unselectedTab'}
                        style={{
                          // borderRight: '1px solid black',
                          width: '100%',
                          padding: '17px',
                          textAlign: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (activeTab !== '1') {
                            getInfo(true);
                            toggle('1');
                          }
                        }}
                      >
                        <h5 style={{ margin: 0 }}>My Schedule</h5>
                      </div>
                      <div style={verticalLine} />
                    </Fragment>
                  )}

                  <div
                    className={activeTab === '2' ? 'selectedTab' : 'unselectedTab'}
                    style={{
                      // borderRight: '1px solid black',
                      width: '100%',
                      padding: '17px',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (activeTab !== '2') {
                        toggle('2');
                        sortCandidates(currentCandidateSortType, false);
                        if (reduxDate === 'Prescreen' || reduxDate.pk_InterviewDate === 'Prescreen') {
                          getPrescreen();
                        } else {
                          getCandidateForDate();
                        }
                      }
                    }}
                  >
                    <h5 style={{ margin: 0 }}>{candidateInfo ? candidateInfo.length : 0} Candidates</h5>
                  </div>
                  {hasSchedule && !isPrescreen && !evaluationsLocked && !DisableCandidateEvaluations && (
                    <Fragment>
                      <div style={verticalLine} />

                      <div
                        className={activeTab === '4' ? 'selectedTab' : 'unselectedTab'}
                        style={{
                          // borderRight: '1px solid black',
                          width: '100%',
                          padding: '17px',
                          textAlign: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (activeTab !== '4') {
                            toggle('4');
                            sortCandidates(currentCandidateSortType, true);
                            getNeedsAttention();
                          }
                        }}
                      >
                        <h5 style={{ margin: 0 }}>{incompleteCandidates.length} Incomplete</h5>
                      </div>
                      <div style={verticalLine} />
                    </Fragment>
                  )}
                  <div
                    className={
                      (activeTab === '3' ? 'selectedTab ' : 'unselectedTab ') +
                      (disableEvaluation || evaluationsLocked || DisableCandidateEvaluations ? 'disable-tab' : '')
                    }
                    style={{
                      // borderRight: '1px solid black',
                      width: '100%',
                      padding: '17px',
                      textAlign: 'center',
                      cursor:
                        disableEvaluation || evaluationsLocked || DisableCandidateEvaluations ? 'default' : 'pointer',
                    }}
                    onClick={() => {
                      if (evaluationsLocked) {
                        informUserEvalLocked();
                        return;
                      }

                      if (DisableCandidateEvaluations) {
                        Swal.fire('Hidden', 'Evaluation has been hidden by your administrator.', 'warning');
                        return;
                      }

                      if (disableEvaluation) return;

                      getEvalAndChangeTab();
                    }}
                  >
                    <h5 style={{ margin: 0 }}>
                      {DisableCandidateEvaluations ? (
                        <span>
                          <FontAwesomeIcon icon={faEyeSlash} id="hidden_evaluation" />{' '}
                          <UncontrolledTooltip target="hidden_evaluation"> Hidden by Administrator</UncontrolledTooltip>
                        </span>
                      ) : evaluationsLocked ? (
                        <span>
                          <FontAwesomeIcon icon={faLock} />{' '}
                        </span>
                      ) : null}
                      Evaluation
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>

            <Row
              style={{
                width: '100%',
                margin: 0,
              }}
            >
              <Col md="12" style={{ padding: 0 }}>
                {renderTabContents()}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col sm="4" style={{ paddingRight: 0, paddingLeft: 0 }}>
        <div
          className=""
          style={{ display: 'flex', flexDirection: 'row', overflowY: 'clip', maxHeight: `calc(100vh - 330px)` }}
        >
          <Portfolio
            activeTab={activeTab}
            allDayRoom={allDayRoom}
            candidateEvaluationStatus={candidateEvaluationStatus}
            carouselIndex={carouselIndex}
            candidateInfo={candidateInfo}
            checkIfEvalsAreLocked={checkIfEvalsAreLocked}
            dataTypes={dataTypes}
            date={date}
            dContext={dContext}
            disableJoinMeetingButton={disableJoinMeetingButton}
            DNRStatus={DNRStatus}
            evaluationsLocked={evaluationsLocked}
            firstBlockEarlyJoin={firstBlockEarlyJoin}
            getEvalAndChangeTab={() => getEvalAndChangeTab()}
            getCandidate={getCandidate}
            handleMeetingClick={handleMeetingClick}
            handleZoomClick={handleZoomClick}
            interviewIsToday={interviewIsToday}
            isEvaluate={date !== 'Prescreen' && reduxDate !== 'Prescreen'}
            lockedStatuses={lockedStatuses}
            requestVirtualMeetingEvaluator={requestVirtualMeetingEvaluator}
            requestZoomMeeting={requestZoomMeeting}
            schedule={schedule || undefined} // if schedule us null pass in undefined so it gets it's default value in
            Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
            Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
            selectedTimeSlot={selectedTimeSlot}
            selectedCandidate={selectedCandidate}
            selectedEvaluator={selectedEvaluator}
            setCarouselIndex={setCarouselIndex}
            setDisableJoinMeetingButton={setDisableJoinMeetingButton}
            setDNRStatus={setDNRStatus}
            setSelectedCandidate={setSelectedCandidate}
            setLargePhoto={setLargePhoto}
            setShowLargePhoto={setShowLargePhoto}
            userIdOnCurrentInterview={userIdOnCurrentInterview}
            Status4SelectedDNR={
              candidateEvaluation && candidateEvaluation.Status ? candidateEvaluation.Status.EnableDoNotRank : false
            }
          />
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps, null)(EvaluateBrowser);
