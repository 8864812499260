import { faInfoCircle, faSpinner, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import React, { Fragment, useEffect, useRef, useState, useContext } from 'react';
import { useAlert, Provider as AlertProvider } from 'react-alert';
import Moment from 'react-moment';
import BellSound from '../../../assets/sounds/shipbell.wav';
import { HeaderWrapper } from '../InterviewDatesWrapper.styles';
import { Alert, Button, Input } from 'reactstrap';
import { DepartmentContext } from '../../../DepartmentWrapper';
// import io from 'socket.io-client';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import {
  getPercentageTime,
  renderCandidateNotFound,
  shouldJoinMeetingButtonBeVisisble,
  renderMeetingDetailsCard,
  checkForScheduleDisabledButton,
  getArrayIndexesWithDifferencesViaTimeSlot,
  urlWithHttpProtocol,
  isNotificationsSupported,
  checkIfMeetingIsBeforeCurrentJoinedMeeting,
} from '../../../Common.functions';
import { createTimeTracker } from '../../../Common.jsx';
import { fetchDataAgnostic } from '../../../Services/dataApi';
import { cssSizes } from '../../Common.style';
import Error from '../../Statuses/Error';
import Loading from '../../Statuses/Loading';
import { demoUserObject, getNextFriday, mockScheduledTimes } from '../demo';
import { CustomCard, CustomCardHeader, CustomCol } from '../InterviewDatesWrapper.styles';
import { CustomTable } from './InterviewDateConfirm.styles';
import { useAuth0 } from '../../../auth0/reactAuth0Spa';
import CandidateHeader from '../../../Header/header.container';
import NotifAlertTemplate from '../../RezRATE/Common/NotifAlertTemplate/NotifAlertTemplate';
const replaceWithNumber = '|###|';
const pendingStatus = `You're pending`;

const TDPadding = styled.td`
  width: 25%;
  ${cssSizes.phoneLandscape`
    width: 0;
  `}
  ${cssSizes.tabletLandscape`
    width: 10vw;
  `}
`;

let highlightClearTimeout = null;

const InterviewSchedule = (props) => {
  const alert = useAlert();

  React.useEffect(() => {
    var timerID = setInterval(() => tick(), 500);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  const isDemo = () => {
    const { match = {} } = props;
    const { params = {} } = match;
    const { id = 'DEMO' } = params;

    if (id.toUpperCase() === 'DEMO') {
      return true;
    }
    return false;
  };

  const clientID = props.match.params.id;
  const [demo, setDemo] = useState(isDemo());
  let elementsRef;
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [currentTimeAsMoment, setCurrentTimeAsMoment] = useState(moment(Date.now()));
  const [topOpacity, setTopOpacity] = useState(0);
  const [headerOpacity, setHeaderOpacity] = useState(0);
  const [heights, setHeights] = useState(null);
  const [isLocalTime, setIsLocalTime] = useState(true);
  const [passWord, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [scheduledTimes, setScheduledTimes] = useState([]);
  const [firstBlockEarlyJoinInMinutes, setFirstBlockEarlyJoinInMinutes] = useState(0);
  const [interviewIsToday, setInterviewIsToday] = useState(false);
  const [error, setError] = useState(false);
  const [mainRoomDescription, setMainRoomDescription] = useState(null);

  const [displayJoinMeetingButtonInfo, setDisplayJoinMeetingButtonInfo] = useState(false);
  const [copied, setCopied] = useState(false);
  const [meetingId, setMeetingId] = useState(null);
  const [statusPending, setStatusPending] = useState(true);

  const [interviewDate, setInterviewDate] = useState(null);
  const [noData, setNoData] = useState(false);
  const [showMeetingCard, setShowMeetingCard] = useState(false);
  const [tableRowOpacity, setTableRowOpacity] = useState(0);
  const [virtualMeetingMode, setVirtualMeetingMode] = useState('');
  const [demoTimeDifference, setDemoTimeDifference] = useState(null);
  const [zoomPassword, setZoomPassword] = useState('');

  const [preventJoinMeetingClick, setPreventJoinMeetingClick] = useState(false);
  const [hasRoomNumber, setHasRoomNumber] = useState(false);
  const [zoomInfo, setZoomInfo] = useState([]);

  const [meetingLink, setMeetingLink] = useState('');
  const [nonZoomInfo, setNonZoomInfo] = useState('');

  const [passingPeriod, setPassingPeriod] = useState(null);
  const [scheduleWithPassingPeriods, setScheduleWithPassingPeriods] = useState({}); // {pk_Timeslot: endTimeWithPassingPeriod}, used for alerts that play befoer end of event + end of passing period
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [firstEvent, setFirstEvent] = useState(); // First non-filler event/meeting
  const [validCandidate, setValidCandidate] = useState(true);
  const [indexesWithDifferences, setIndexesWithDifferences] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [alertedEvents, setAlertedEvents] = useState([]);
  const [pk_Candidate, set_pk_Candidate] = useState();
  const [rawData, setRawData] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoadingSchedule, setIsLoadingSchedule] = useState(false);
  const [hasScheduleError, setHasScheduleError] = useState(false);
  const [noScheduleAssigned, setHasNoScheduleAssigned] = useState(false);
  const [isMalformedSchedule, setIsMalformedSchedule] = useState(false);

  const [isSystemTimeInSync, setIsSystemTimeInSync] = useState(true);
  const [showTimeSyncWarningBanner, setShowTimeSyncBanner] = useState(false);
  const [timeSyncOffset, setTimeSyncOffset] = useState(0); // minutes

  const [minutesToUpdateDisplay, setMinutesToUpdateDisplay] = useState(60);

  // const [socket, setSocket] = useState(io.connect('http://localhost:8080/'));
  const [allDayRoom, setAllDayRoom] = useState(null);
  const headerRef = useRef(null);
  const tableHeadRef = useRef(null);
  const tableRowRef = useRef(null);
  const minutesToUpdateRef = useRef(null);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const dContext = useContext(DepartmentContext);
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const isPreview = query.get('preview');

  const secondsToWait = 60;
  // const secondsToWait = 10;
  let hasFiredOnLoadSwal = false;

  useEffect(() => {
    if (isDemo()) {
      if (!('Notification' in window)) {
      } else {
        if (isNotificationsSupported()) {
          Notification.requestPermission();
        }
      }
    }

    getDepartmentInfo();
    getAlertedEvents();

    let audio = new Audio(BellSound);
    audio.volume = 0.0;
    audio
      .play()
      // .then(() => {
      //   console.log('wakeup 0 volume played fine');
      // })
      .catch((err) => {
        console.log('wakeup 0 volume err: ', err);
      });

    setInterval(() => {
      if (minutesToUpdateRef.current > 0) {
        setMinutesToUpdateDisplay(minutesToUpdateRef.current - 1);
        minutesToUpdateRef.current = minutesToUpdateRef.current - 1;
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (indexesWithDifferences && indexesWithDifferences.length > 0) {
      clearTimeout(highlightClearTimeout);
    }
  }, [indexesWithDifferences]);

  useEffect(() => {
    if (!demo) {
      getData();
      // const recheckWait = 5 * 60 * 1000;

      setInterval(() => {
        getData();
      }, secondsToWait * 1000);
    } else {
      const nextFriday = getNextFriday();
      props.setUser(demoUserObject);
      setLoading(false);
      setIsLocalTime(true);
      setInterviewDate(nextFriday);
      setInterviewIsToday(nextFriday);
      setScheduledTimes(mockScheduledTimes);
      Swal.fire('This is a demo!', 'These are not real appointments.');
    }
  }, [clientID]);

  useEffect(() => {
    if (headerRef.current) {
      const { clientHeight } = headerRef.current;
      setTopOpacity(clientHeight);
    }
  }, [headerRef]);

  useEffect(() => {
    if (tableHeadRef.current) {
      const { clientHeight } = tableHeadRef.current;
      setHeaderOpacity(clientHeight);
    }
  }, [tableHeadRef]);

  useEffect(() => {
    if (tableRowRef.current) {
      const { clientHeight } = tableRowRef.current;
      setTableRowOpacity(clientHeight);
    }
  }, [tableRowRef]);

  useEffect(() => {
    if (alertedEvents && alertedEvents.length > 0) {
      localStorage.setItem('candidate_alertedEvents', JSON.stringify(alertedEvents));
    }
  }, [alertedEvents]);

  const tick = () => {
    const rightNow = new Date();
    const now = moment();
    if (scheduledTimes && scheduledTimes.length > 0 && document.getElementById('tableRow0')) {
      let heights = [];
      const lastJoinedMeeting = localStorage.getItem('candidate_lastJoinedMeeting');

      scheduledTimes.forEach((element, i) => {
        const itemIsBeforeCurrentMeeting = lastJoinedMeeting
          ? checkIfMeetingIsBeforeCurrentJoinedMeeting({
              lastJoinedMeeting: lastJoinedMeeting ? JSON.parse(lastJoinedMeeting) : null,
              itemToCheck: element,
            })
          : false;

        const height = document.getElementById('tableRow' + i).clientHeight;
        heights.push(height);

        if (itemIsBeforeCurrentMeeting) {
          return;
        }

        // if (i > 0 && !scheduledTimes[i - 1].isFiller) {
        checkForAlarm(element);
        // }
      });

      setHeights(heights);
    }

    // Check if "Early" message disappears 4:59 minutes before event.
    const earlyMessageCutOffTime = firstEvent ? moment(firstEvent.StartTimeAsMoment) : null;
    if (earlyMessageCutOffTime) {
      earlyMessageCutOffTime.subtract(firstBlockEarlyJoinInMinutes || 0, 'minutes');
    }

    if (firstEvent && now.isBefore(earlyMessageCutOffTime)) {
      setDisplayJoinMeetingButtonInfo(true);
    } else {
      setDisplayJoinMeetingButtonInfo(false);
    }

    if (demoTimeDifference && demo) {
      rightNow.setSeconds(rightNow.getSeconds() + demoTimeDifference);
      setCurrentTime(rightNow);
      setCurrentTimeAsMoment(moment(rightNow));
    } else {
      const rightNow = new Date();
      setCurrentTimeAsMoment(moment(rightNow));
      setCurrentTime(rightNow);
    }
  };

  const checkForAlarm = (item) => {
    const {
      Users = [],
      UUID,
      EndTimeAsMoment,
      StartTimeAsMoment,
      EndTime,
      StartTime,
      pk_InterviewDate,
      pk_ScheduleAssignment,
      pk_Timeslot,
      pk_ScheduleBlock,
      isFiller,
      isCustomMeeting,
      isPassingPeriod,
      isScheduleBlock,
      isFlexEvent,
    } = item;
    const joinKey = `${pk_Timeslot}_${pk_ScheduleAssignment}_${pk_ScheduleBlock}_${StartTime}_${EndTime}`;
    const itemIsBreak = !isCustomMeeting && !isFlexEvent && !isScheduleBlock && !isPassingPeriod;

    const joinedMeetings = localStorage.getItem('joinedMeetings') || [];
    const trackerKey = `${pk_Timeslot}_${pk_ScheduleAssignment}_${pk_ScheduleBlock}`;

    if (
      (Users.length > 0 || isFlexEvent || isCustomMeeting || isScheduleBlock) &&
      !joinedMeetings.includes(joinKey) &&
      !isFiller
    ) {
      const eventEndTime = EndTimeAsMoment;
      const eventEndTimeWithPassing = scheduleWithPassingPeriods[trackerKey];

      const formattedEndTime = EndTimeAsMoment.format('MMM DD, YYYY hh:mm A');
      const formattedStartTime = StartTimeAsMoment.format('MMM DD, YYYY hh:mm A');

      const now = moment();
      const realAlertedEvents = localStorage.getItem('candidate_alertedEvents')
        ? JSON.parse(localStorage.getItem('candidate_alertedEvents'))
        : [];
      const newAlertedEvents = realAlertedEvents.slice();
      alerts.forEach((a) => {
        const { Message, pk_Alert, Type } = a;
        const alertMinutes = a.Minutes;
        const key = `candidate_${pk_Candidate}_(${formattedStartTime})_(${formattedEndTime})_${pk_Alert}_${pk_Timeslot}_${pk_InterviewDate}`;
        let diffActual = -2;

        if (Type === 'End of Meeting') {
          diffActual = eventEndTime.diff(now, 'minutes', true);
        }

        if (Type === 'End of Passing') {
          diffActual = eventEndTimeWithPassing.diff(now, 'minutes', true);
        }

        // if (Type === 'End of Meeting') {
        //   diffActual = eventEndTime.diff(now, 'minutes', true);
        // } else if (Type === 'End of Passing') {
        //   diffActual = eventEndTimeWithPassing.diff(now, 'minutes', true);
        // } else {
        //   // console.log('Unknown Alert TYPE: ', Type);
        // }

        const alertIsNow = diffActual >= -1 && diffActual <= alertMinutes;
        const alertIsInThePast = diffActual < -1;

        if (alertIsNow && !realAlertedEvents.includes(key)) {
          if (isNotificationsSupported()) {
            try {
              new Notification(`${Message}`);
            } catch (e) {
              console.log('Failed to fire notif. Err: ', e);
            }
          }

          if (a.Sound === 'Bell') {
            let audio = new Audio(BellSound);
            audio.volume = 0.9;
            audio
              .play()
              // .then(() => {
              //   console.log('alert played fine');
              // })
              .catch((err) => {
                console.log('alert failed to play err: ', err);
              });
          }
          alert.info(Message);
        }

        if (alertIsNow || alertIsInThePast) {
          if (!newAlertedEvents.includes(key)) {
            newAlertedEvents.push(key);
            localStorage.setItem('candidate_alertedEvents', JSON.stringify(newAlertedEvents));
            setAlertedEvents(newAlertedEvents);
          }
        }
      });
    } else {
      if (!joinedMeetings.includes(joinKey) && pk_Timeslot == 1040) {
        //
      }
    }
  };

  const processSchedulesWithPassingPeriod = (schedule) => {
    const scheduleObject = scheduleWithPassingPeriods;

    schedule.forEach((item, i) => {
      const { pk_ScheduleAssignment, pk_Timeslot, pk_ScheduleBlock, isPassingPeriod, isFiller } = item;
      const key = `${pk_Timeslot}_${pk_ScheduleAssignment}_${pk_ScheduleBlock}`;
      if (isPassingPeriod || isFiller) {
        return;
      }
      let passingPeriodEnd;
      if (schedule[i + 1] && schedule[i + 1].isPassingPeriod) {
        passingPeriodEnd = schedule[i + 1].EndTimeAsMoment;
      } else {
        passingPeriodEnd = schedule[i].EndTimeAsMoment;
      }
      scheduleObject[key] = passingPeriodEnd;
    });
    setScheduleWithPassingPeriods(scheduleObject);
  };

  const getAlertedEvents = () => {
    const newAlertedEvents = localStorage.getItem('candidate_alertedEvents') || [];
    if (newAlertedEvents && newAlertedEvents.length > 0) {
      setAlertedEvents(JSON.parse(newAlertedEvents));
    }
  };

  // Assume the url returned here is processed whether zoom or per-column.
  const getInterviewRoom = (item) => {
    const { pk_InterviewDate, pk_ScheduleAssignment, pk_ScheduleBlock, pk_Timeslot } = item;
    fetchDataAgnostic('department/season/block/candidate/meeting', {
      pk_Department: dContext.department.pk_Department,
      pk_Season: dContext.season.pk_Season,
      UUID: clientID,
      pk_ScheduleBlock,
      pk_InterviewDate,
      pk_Timeslot,
      pk_ScheduleAssignment,
    })
      .then((result) => {
        if (result.data && result.data.JoinURL) {
          const { DialInNumbers = [], JoinURL = '', MeetingId, Password } = result.data;

          if (result.data.JoinURL) {
            const urlFormatted = urlWithHttpProtocol(result.data.JoinURL);
            handleMeetingCardShow({
              urlToUse: urlFormatted,
              meetingId: MeetingId,
              password: Password,
              zoomInfo: DialInNumbers,
              item,
            });
            // setMeetingId(MeetingId);
            // setZoomPassword(Password);
            // setMeetingLink(urlFormatted);
            // setZoomInfo(DialInNumbers);

            // setShowMeetingCard(true);
            // setTimeout(() => {
            //   openURLInNewTab(urlFormatted);
            // }, 1000);
            // setTimeout(() => {
            //   setShowMeetingCard(false);
            // }, 3 * 60 * 1000);
            // localStorage.setItem('candidate_lastJoinedMeeting', JSON.stringify(item));
          } else {
            Swal.fire('Error!', 'No meeting to join. Please contact the coordinator.', 'error');
          }
        }
      })
      .catch((err) => {
        Swal.fire('Error!', `Network error: ${err.message}`, 'error');
      });
  };

  const openURLInNewTab = (urlFormatted) => {
    try {
      // throw 'TOWADERS';
      window.open(urlFormatted, '_blank').focus();
    } catch (e) {
      alert.error('Popup blocker detected. Please check your browser settings.');
    }
  };

  const handleBreakRoomClick = () => {
    if (props.breakUrl) {
      const urlToUse = props.breakUrl.url;
      setMeetingId('');
      setZoomPassword('');
      setMeetingLink(urlToUse);
      setZoomInfo([]);
      setShowMeetingCard(true);
      setNonZoomInfo(urlToUse || '');

      setTimeout(() => {
        openURLInNewTab(urlToUse);
      }, 1000);

      setTimeout(() => {
        setShowMeetingCard(false);
      }, 3 * 60 * 1000);

      // window.open(props.breakUrl.url, '_blank').focus();
    } else {
      Swal.fire('Error!', 'No link to join. Please contact the coordinator.', 'error');
    }
  };

  const showJoinMeetingButton = (message, url, priorityUrl, Schedules4Portal__pkUUID_Schedule, item, i) => {
    const now = moment();
    const earlyMessageCutOffTime = firstEvent ? moment(firstEvent.StartTimeAsMoment) : null;

    if (earlyMessageCutOffTime) {
      earlyMessageCutOffTime.subtract(5, 'minutes');
    }

    const isTooEarly = now.isBefore(earlyMessageCutOffTime);

    if (message === 'BREAK')
      return (
        <Fragment>
          <br />
          {props.breakUrl.url ? (
            <Button
              color="warning"
              disabled={checkForScheduleDisabledButton(item, now)}
              size="sm"
              onClick={(e) => {
                e.preventDefault();

                if (!demo) {
                  handleBreakRoomClick();
                } else {
                  Swal.fire('This is a demo!', 'This would normally join your meeting.');
                }
              }}
            >
              {props.breakUrl && props.breakUrl.buttonText ? props.breakUrl.buttonText : 'Visit Break Room'}
            </Button>
          ) : null}
        </Fragment>
      );
    if (virtualMeetingMode !== 'Disabled') {
      return (
        <Fragment>
          <br />
          <Button
            color={'success'}
            size="sm"
            disabled={preventJoinMeetingClick || checkForScheduleDisabledButton(item, now)}
            style={{ minWidth: '95px' }}
            onClick={(e) => {
              let audio = new Audio(BellSound);
              audio.volume = 0.0;
              audio.play();

              e.preventDefault();
              // if (isTooEarly) {
              //   Swal.fire('Notice', `It's not time to join this meeting yet.`, 'info');
              //   return;
              // }

              const meetingBeforeThis = i > 1 ? scheduledTimes[i - 2] : null;
              if (meetingBeforeThis) {
                const {
                  Users = [],
                  UUID,
                  EndTimeAsMoment,
                  EndTime,
                  StartTime,
                  pk_Timeslot,
                  pk_ScheduleAssignment,
                  pk_ScheduleBlock,
                } = meetingBeforeThis;

                const joinKey = `${pk_Timeslot}_${pk_ScheduleAssignment}_${pk_ScheduleBlock}_${StartTime}_${EndTime}`;
                const joinedMeetings =
                  localStorage.getItem('joinedMeetings') && localStorage.getItem('joinedMeetings').length > 0
                    ? JSON.parse(localStorage.getItem('joinedMeetings'))
                    : [];
                if (!joinedMeetings.includes(joinKey)) {
                  joinedMeetings.push(joinKey);
                  localStorage.setItem('joinedMeetings', JSON.stringify(joinedMeetings));
                }
              }

              if (!demo) {
                const { virtualMeetingMode, interviewDateMeta = {} } = rawData;
                const { MainRoomURL, MainRoomURLDetails } = interviewDateMeta;

                const { EventName, CustomMeetingTitle, Users } = item;

                if (Users && Users.length > 0) {
                  if (virtualMeetingMode === 'External') {
                    if (MainRoomURL) {
                      handleMeetingCardShow({
                        urlToUse: MainRoomURL,
                        password: '',
                        nonZoomInfo: MainRoomURLDetails,
                        item,
                      });

                      // setMeetingId('');
                      // setZoomPassword('');
                      // setMeetingLink(MainRoomURL);
                      // setZoomInfo([]);
                      // setShowMeetingCard(true);
                      // setNonZoomInfo(MainRoomURLDetails || '');

                      // localStorage.setItem('candidate_lastJoinedMeeting', JSON.stringify(item));

                      // setTimeout(() => {
                      //   openURLInNewTab(MainRoomURL);
                      // }, 1000);
                      // setTimeout(() => {
                      //   setShowMeetingCard(false);
                      // }, 3 * 60 * 1000);
                    } else {
                      Swal.fire('Error!', 'No link to join. Please contact the coordinator.', 'error');
                    }
                  } else {
                    getInterviewRoom(item);
                  }
                } else if (EventName) {
                  const { UrlType, CustomUrl, CustomUrlDescription } = item;
                  let urlToOpen = MainRoomURL;

                  if (UrlType === 'Custom') {
                    urlToOpen = CustomUrl;
                  }

                  if (!urlToOpen) {
                    Swal.fire(
                      'Error!',
                      'No link to join. No event has been created. Please contact your coordinator.',
                      'error',
                    );
                  } else {
                    if (virtualMeetingMode != 'Disabled') {
                      handleMeetingCardShow({
                        urlToUse: urlToOpen,
                        password: '',
                        nonZoomInfo: CustomUrlDescription || '',
                        item,
                      });

                      // localStorage.setItem('candidate_lastJoinedMeeting', JSON.stringify(item));

                      // setMeetingId('');
                      // setZoomPassword('');
                      // setMeetingLink(urlToOpen);
                      // setZoomInfo([]);
                      // setShowMeetingCard(true);
                      // setNonZoomInfo(CustomUrlDescription || '');

                      // setTimeout(() => {
                      //   openURLInNewTab(urlToOpen);
                      // }, 1000);
                      // setTimeout(() => {
                      //   setShowMeetingCard(false);
                      // }, 3 * 60 * 1000);
                    }
                  }
                } else if (CustomMeetingTitle) {
                  const { CustomMeetingUrl, VirtualRoomType, CustomMeetingDetails } = item;
                  let urlToOpen = VirtualRoomType === 'Custom' ? CustomMeetingUrl : MainRoomURL;

                  if (!urlToOpen) {
                    Swal.fire(
                      'Error!',
                      'No link to join. No event has been created. Please contact your coordinator.',
                      'error',
                    );
                  } else {
                    if (virtualMeetingMode != 'Disabled') {
                      // localStorage.setItem('candidate_lastJoinedMeeting', JSON.stringify(item));
                      handleMeetingCardShow({
                        urlToUse: urlToOpen,
                        password: '',
                        nonZoomInfo:
                          VirtualRoomType === 'Custom' ? CustomMeetingDetails || '' : MainRoomURLDetails || '',
                        item,
                      });

                      // setMeetingId('');
                      // setZoomPassword('');
                      // setMeetingLink(urlToOpen);
                      // setZoomInfo([]);
                      // setShowMeetingCard(true);
                      // setNonZoomInfo(
                      //   VirtualRoomType === 'Custom' ? CustomMeetingDetails || '' : MainRoomURLDetails || '',
                      // );

                      // setTimeout(() => {
                      //   openURLInNewTab(urlToOpen);
                      // }, 1000);

                      // setTimeout(() => {
                      //   setShowMeetingCard(false);
                      // }, 3 * 60 * 1000);
                    }
                  }
                } else {
                  Swal.fire(
                    'Error!',
                    'No Evaluators found. No Event found. Please contact your coordinator. ',
                    'error',
                  );
                }
              } else {
                Swal.fire('This is a demo!', 'This would normally join your meeting.');
              }
            }}
          >
            {preventJoinMeetingClick ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              `${item.EventName ? 'Join Event' : 'Join Meeting'}`
            )}
          </Button>
        </Fragment>
      );
    }
  };

  const handleMeetingCardShow = (options) => {
    const { urlToUse, password = '', nonZoomInfo = '', meetingId = '', zoomInfo = [], item } = options;
    setMeetingId(meetingId);
    setZoomPassword(password);
    setMeetingLink(urlToUse);
    setZoomInfo(zoomInfo);
    setShowMeetingCard(true);
    setNonZoomInfo(nonZoomInfo || '');

    localStorage.setItem('candidate_lastJoinedMeeting', JSON.stringify(item));

    setTimeout(() => {
      openURLInNewTab(urlToUse);
    }, 1000);

    setTimeout(() => {
      setShowMeetingCard(false);
    }, 3 * 60 * 1000);
  };

  const determineMessage = (scheduleItem) => {
    const { CustomMeetingTitle, Users, isPassingPeriod, isFlexEvent, EventName } = scheduleItem;

    if (isFlexEvent) {
      return EventName;
    }
    if (isPassingPeriod) {
      return 'Passing Period';
    }
    if (Users && Users.length > 0) {
      let message = null;
      const userCount = Users.length;
      Users.forEach((userItem) => {
        const { UserLast, UserFirst } = userItem;
        if (!message) {
          message =
            userCount >= 3
              ? `${UserFirst[0]}. ${UserLast}`
              : userCount == 2
              ? `${UserFirst} ${UserLast}`
              : `${UserLast}, ${UserFirst} `;
        } else {
          message +=
            userCount >= 3
              ? `, ${UserFirst[0]}. ${UserLast}`
              : userCount == 2
              ? ` & ${UserFirst} ${UserLast}`
              : `, ${UserFirst} ${UserLast} `;
        }
      });
      return message;
    }

    if (CustomMeetingTitle && CustomMeetingTitle !== '') {
      return CustomMeetingTitle;
    }

    // if it fits none of the above criteria, it's a break
    return 'Break';
  };

  const getData = () => {
    setIsLoadingSchedule(true);
    fetchDataAgnostic('schedule/candidateId', {
      UUID: clientID,
      pk_Department: dContext.department.pk_Department,
      pk_Season: dContext.season.pk_Season,
    })
      .then((res) => {
        if (res.data === false) {
          setHasNoScheduleAssigned(true);
        } else {
          const {
            interviewDate,
            currentServerTime,
            schedule = [],
            interviewDateMeta = {},
            alerts,
            virtualMeetingMode,
            firstBlockEarlyJoinInMinutes,
          } = res.data;
          const { MainRoomURL, FirstName = '', LastName = '' } = interviewDateMeta;
          setRawData(res.data);
          setFirstName(FirstName);
          setLastName(LastName);

          if (isPreview && !hasFiredOnLoadSwal) {
            hasFiredOnLoadSwal = true;
            Swal.fire({
              title: 'Warning!',
              text: `You are acting on behalf of ${LastName}${FirstName && LastName ? ', ' : ''}${FirstName}`,
              icon: 'warning',
              confirmButtonText: 'I Understand',
              confirmButtonColor: '#d33',
            });
          }
          setFirstBlockEarlyJoinInMinutes(firstBlockEarlyJoinInMinutes);
          setVirtualMeetingMode(virtualMeetingMode);

          if (MainRoomURL) {
            props.setBreakUrl({ url: MainRoomURL, buttonText: 'Visit Break Room' });
          }

          if (res.data && res.data.noScheduledDate) {
            const { CandidateUUID } = res.data;
            return history.push(`/candidate/${CandidateUUID}`);
          }

          setInterviewDate(interviewDate);

          let newFirstEvent = null;
          schedule.forEach((item, i) => {
            item.message = determineMessage(item);

            item.StartTimeAsMoment = moment(item.StartTime);
            item.EndTimeAsMoment = moment(item.EndTime);
            if (newFirstEvent == null && !item.isFiller) {
              newFirstEvent = item;
            }
          });

          if (newFirstEvent) {
            setFirstEvent(newFirstEvent);
          }

          const { DateOfInterview, fk_Candidate } = interviewDateMeta;

          set_pk_Candidate(fk_Candidate);
          props.setPartDocumentTitle(moment(new Date(DateOfInterview)).format('MMM. D, YYYY'));
          let newStartMonth = null;
          let newEndMonth = null;

          if (schedule.length > 0) {
            if (schedule[0].StartTimeAsMoment) {
              newStartMonth = moment(schedule[0].StartTimeAsMoment).format('MMM DD, YYYY');
              newEndMonth = schedule[schedule.length - 1].EndTimeAsMoment.format('MMM DD, YYYY');
              setStartMonth(newStartMonth);
              setEndMonth(newEndMonth);
            } else {
              setIsMalformedSchedule(true);
              return;
            }
          } else {
            if (interviewDate) {
              newStartMonth = moment.tz(interviewDate, null).format('MMM DD, YYYY');
              newEndMonth = newStartMonth;
              setStartMonth(newStartMonth);
              setEndMonth(newStartMonth);
            } else {
              setIsMalformedSchedule(true);
              return;
            }
          }

          const isToday =
            newStartMonth === moment(currentServerTime).format('MMM DD, YYYY') ||
            newEndMonth === moment(currentServerTime).format('MMM DD, YYYY');
          setInterviewIsToday(isToday);

          processSchedulesWithPassingPeriod(schedule);
          setScheduledTimes((prevState) => {
            const newIndexesWithDifferences = getArrayIndexesWithDifferencesViaTimeSlot(
              schedule || [],
              prevState || [],
              ['Users'],
            );

            if (newIndexesWithDifferences && newIndexesWithDifferences.length > 0) {
              setIndexesWithDifferences(newIndexesWithDifferences);

              Swal.fire({
                title: 'Schedule has been updated',
                text: 'Changes are highlighted!',
              }).then(() => {
                highlightClearTimeout = setTimeout(() => {
                  setIndexesWithDifferences([]);
                }, [8000]);
              });
            }

            return schedule;
          });

          setAlerts(alerts);
        }
        setError(false);
        setLoading(false);
        setIsLoadingSchedule(false);
        setHasScheduleError(false);
        setMinutesToUpdateDisplay(secondsToWait);
        minutesToUpdateRef.current = secondsToWait;
      })
      .catch((err) => {
        setIsLoadingSchedule(false);
        setHasScheduleError(true);
        setMinutesToUpdateDisplay(secondsToWait);
        minutesToUpdateRef.current = secondsToWait;

        if (err && err.response && err.response.status === 404) {
          setValidCandidate(false);
        } else {
          alert.error('Error loading schedule');
          // Swal.fire('Error!', `${err.message}`, 'error');
          setError(true);
        }
      });
  };

  const getDepartmentInfo = () => {
    if (props && props.match && props.match.params && props.match.params.id) {
      fetchDataAgnostic('department/season/candidate/scheduleId', { scheduleUUID: props.match.params.id })
        .then((result) => {
          props.setUser(result.data);
          const {
            HasDeclinedInterview,
            CandidateUUID,
            HasBeenSentWaitlist,
            ScheduledInterviewId,
            currentServerTime,
          } = result.data;

          const serverTime = result.data.currentServerTime;
          const convertedCorrectedTime = moment.tz(serverTime, moment.tz.guess());
          const systemTime = moment();
          const timeDifference = convertedCorrectedTime.diff(systemTime, 'minutes');

          if (Math.abs(timeDifference) >= 2) {
            setShowTimeSyncBanner(true);
          }

          setTimeSyncOffset(timeDifference);

          // if the candidate has declined the interview, redirect them to the interview date
          // selection page
          if (HasDeclinedInterview) {
            history.push(`/candidate/${CandidateUUID}`);
          }
          // if they have not been sent their waitlist data or they're not scheduled then they're pending
          if (HasBeenSentWaitlist === false && ScheduledInterviewId == null) {
            setStatusPending(true);
          } else {
            setStatusPending(false);
          }
        })
        .catch((err) => {});
    }
  };

  // Seems REDUNDANT with determineMessage - Tere
  const renderMessage = (item, isPassingPeriod) => {
    if (item.isFiller) {
      return 'Break';
    }
    if (isPassingPeriod) {
      return 'Passing Period';
    }
    if (item.CustomMeetingTitle) {
      return item.CustomMeetingTitle;
    }
    if (item.Users) {
      let message = '';

      item.Users.forEach((item) => {
        const { UserFirst, UserLast } = item;
        message += `${UserLast}, ${UserFirst}`;
      });
      return message;
    }
    return item.message;
  };

  const renderStatusMessage = () => {
    return (
      <div
        style={{
          color: hasScheduleError ? 'red' : null,
          textAlign: 'center',
          fontSize: 12,
          fontWeight: 'bold',
          padding: 5,
        }}
      >
        {// isLoadingSchedule ? (
        // 'Updating data . . .'
        // ) :

        hasScheduleError ? `Error Updating data! Retrying in ${`${minutesToUpdateDisplay}s`}. . .` : <br />}
      </div>
    );
  };

  const populateDates = () => {
    let dateformatTime;
    let difference;
    let color = null;
    let coverBorder = null;
    const items = scheduledTimes.map((item, i) => {
      const { isPassingPeriod } = item;
      let last = false;
      const stringDate = interviewDate + ' ' + item.Schedules4PortalTime;
      const numberDate = Date.parse(stringDate);
      dateformatTime = new Date(numberDate);

      difference = getPercentageTime(
        currentTimeAsMoment.valueOf(),
        tableRowOpacity,
        item.StartTimeAsMoment.valueOf(),
        item.EndTimeAsMoment.valueOf(),
      );

      const joinMeetingButtonVisisble = shouldJoinMeetingButtonBeVisisble(item, demo, currentTimeAsMoment);

      const isCurrentTimeSlot = difference.height !== 0 && difference.height !== '100%';
      const shouldBeVisible = demo ? isCurrentTimeSlot : joinMeetingButtonVisisble;

      const showHighlight = indexesWithDifferences.includes(i);
      const messageFlashRed = false;
      const message = item.message; // renderMessage(item, isPassingPeriod);

      return (
        <tr
          ref={tableRowRef}
          key={dateformatTime + i}
          style={{ backgroundColor: showHighlight ? '#c4fcbb' : item.color }}
          className={isPassingPeriod ? 'dashed_top_line' : ''}
        >
          <TDPadding className="d-none d-sm-table-cell" />
          {interviewIsToday &&
            isLocalTime &&
            createTimeTracker(heights, i, difference, color, coverBorder, null, isPassingPeriod)}
          <td id={'tableRow' + i} style={{ verticalAlign: 'middle' }}>
            <p className={'red_staging '} style={{ margin: 0 }}>
              <Moment style={{ whiteSpace: 'nowrap' }} format="h:mm A">
                {item.StartTimeAsMoment}
              </Moment>
            </p>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <p className={'red_staging '} style={{ margin: 0, textAlign: 'center' }}>
              {message}
              <br />
              {item.RoomLabel ? <div>{item.RoomLabel}</div> : null}

              {/* {item.TimeSlots4PortalAltRoom !== '' ? item.TimeSlots4PortalAltRoom : item.Schedules4PortalRoomNumber} */}

              {shouldBeVisible &&
                // interviewIsToday &&
                !isPassingPeriod &&
                showJoinMeetingButton(
                  message.toUpperCase(),
                  item.TimeSlots4PortalJoinManualURL,
                  item.Schedules4PortalJoinManualURL,
                  item.Schedules4Portal__pkUUID_Schedule,
                  item,
                  i,
                )}
            </p>
          </td>

          <TDPadding className="d-none d-sm-table-cell" />
        </tr>
      );
    });
    return items;
  };

  if (!validCandidate) {
    return <div style={{ width: '100%' }}>{renderCandidateNotFound()}</div>;
  }

  if (error) {
    return <Error />;
  }

  if (loading) {
    return (
      <div
        style={{
          height: 'calc(100vh - 100px )',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ height: 100, width: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loading />
        </div>
      </div>
    );
  }

  if (noData) {
    return <Error header={'Schedule is not currently published.'} subHeader="Please check back later." />;
  }

  return (
    <HeaderWrapper>
      <CandidateHeader showNotifTester={!noScheduleAssigned} />
      {noScheduleAssigned || isMalformedSchedule ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            padding: 5,
            width: '100%',
            justifyContent: 'center',
            marginTop: '20vh',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Alert color="danger">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '27vw',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    fontSize: 60,
                  }}
                >
                  {/* <FontAwesomeIcon icon={faTimesCircle} /> */}
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignContent: 'flex-start',
                    textAlign: 'left',
                    paddingLeft: 20,
                  }}
                >
                  <div>
                    {noScheduleAssigned
                      ? 'You are not currently scheduled for an interview.'
                      : isMalformedSchedule
                      ? 'Unable to retrieve valid schedule at this time'
                      : 'Error loading schedule.'}
                  </div>
                  <div>Please contact your coordinator for further assistance.</div>
                </div>
              </div>
            </Alert>
          </div>
        </div>
      ) : (
        <CustomCol key={2} sm={12}>
          <form id="zoom_form" target="_blank"></form>
          {renderMeetingDetailsCard({
            showMeetingCard,
            setShowMeetingCard,
            meetingLink,
            setCopied,
            copied,
            zoomInfo,
            zoomPassword,
            mainRoomDescription,
            meetingId,
            nonZoomInfo,
          })}
          <CustomCard>
            <CustomCardHeader ref={headerRef}>
              <h4>
                Your Schedule
                <br />{' '}
                {startMonth === endMonth
                  ? `${moment(startMonth).format('dddd, MMMM DD, YYYY')}`
                  : `${startMonth} - ${endMonth}`}
              </h4>
              {demo && (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <p>
                    <FontAwesomeIcon icon={faInfoCircle} /> Because you're using the demo, you can change the time of
                    day.
                  </p>
                  <Input
                    style={{ maxWidth: '200px' }}
                    type="select"
                    name="select"
                    id="exampleSelect"
                    onChange={(e) => {
                      if (e.target.value === 'Current Time') {
                        setDemoTimeDifference(null);
                      } else {
                        const rightNow = new Date(Date.now());
                        const newTime = new Date(e.target.value);
                        var difference = newTime.getTime() - rightNow.getTime(); // This will give difference in milliseconds
                        var resultsInSeconds = Math.round(difference / 1000) + 2;
                        setDemoTimeDifference(resultsInSeconds);

                        tick();
                        // setCurrentTime(e.target.value);
                      }
                    }}
                  >
                    <option>Current Time</option>
                    {mockScheduledTimes.map((item) => {
                      return <option value={item.time}>{moment(item.time).format('h:mm a')}</option>;
                    })}
                  </Input>
                </div>
              )}

              <div style={{ color: 'white', fontSize: 14 }}>Times are displayed in your device's local time zone.</div>
            </CustomCardHeader>
            {isPreview && (
              <div style={{ backgroundColor: '#FFD2D2', textAlign: 'center', paddingTop: 5, paddingBottom: 5 }}>
                <h5>
                  You are acting on behalf of <b>{`${lastName}${firstName && lastName ? ', ' : ''}${firstName}`}</b>.
                </h5>
              </div>
            )}
            {displayJoinMeetingButtonInfo && virtualMeetingMode !== 'Disabled' && (
              <div style={{ backgroundColor: '#ffe900', textAlign: 'center' }}>
                <h5>
                  You're early! Join meeting button will become active {firstBlockEarlyJoinInMinutes || 0} minute
                  {firstBlockEarlyJoinInMinutes > 1 ? 's' : ''} before your first meeting.
                  {/* Your early!. Join meeting buttons will not appear until {firstBlockEarlyJoinInMinutes || 0} minutes
                  before your first meeting. */}
                </h5>
              </div>
            )}
            {showTimeSyncWarningBanner && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  textAlign: 'center',
                  // paddingTop: 5,
                  // paddingBottom: 5,
                  backgroundColor: '#f0ad4e',
                  justifyContent: 'center',
                }}
              >
                <span>
                  <b>
                    WARNING: Your system time appears to be improperly set. This may cause issues with the schedule,
                    notifications, and joining meetings.
                  </b>
                </span>
              </div>
            )}
            {renderStatusMessage()}
            <CustomTable>
              <thead ref={tableHeadRef}>
                <tr>
                  <th id="padding-header" className="d-none d-sm-table-cell"></th>
                  {interviewIsToday && isLocalTime && (
                    <th style={{ whiteSpace: 'nowrap', paddingRight: 0 }}>
                      <Moment format="h:mm:ss A">{currentTime}</Moment>
                    </th>
                  )}
                  <th>Time</th>
                  <th style={{ textAlign: 'center' }}>Event</th>
                  {hasRoomNumber && <th style={{ textAlign: 'center' }}>Room</th>}
                  <th className="d-none d-sm-table-cell"></th>
                </tr>
              </thead>
              <tbody>{populateDates()}</tbody>
            </CustomTable>
            {/* {timeWrappers()} */}
          </CustomCard>
        </CustomCol>
      )}
    </HeaderWrapper>
  );
};

const AlertProvidedInterviewSchedule = (props) => {
  return (
    <AlertProvider template={NotifAlertTemplate} timeout={8000}>
      <InterviewSchedule {...props} />
    </AlertProvider>
  );
};
export default AlertProvidedInterviewSchedule; // InterviewSchedule;
